/* eslint-disable */
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import MKBox from "components/MKBox";
import { Grid, Icon, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from "@mui/material";
import MKTypography from "components/MKTypography";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Button from "assets/theme/components/button";
import MKButton from "components/MKButton";
import { ArrowDownward, ArrowUpward, Remove } from "@mui/icons-material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function Stratifikasiukskab() {
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dropdown, setDropdown] = useState(null);
    const [selected, setSelected] = useState('');
    const [total, setTotal] = useState({ persentase: 0, sekolah_strafikasi: 0, jumlah_sekolah: 0, sekolah_sisa: 0 });
    const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

    useEffect(() => {
        setLoading(true);
        axios({
            url: `/get_stratifikasi_kab`,
            method: "GET"
        }).then((e) => {
            const { rows } = e.data;
            setDataList(rows);
            setLoading(false);
            handleTotal(rows);
        })
    }, []);

    const dataListFilter = dataList?.filter((v, i, a) => a.findIndex(t => (t.provinsi === v.provinsi)) === i);

    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    const handleTotal = (rows) => {
        let sekolah_strafikasi = 0;
        let jumlah_sekolah = 0;

        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];

            sekolah_strafikasi = parseInt(sekolah_strafikasi) + parseInt(element.sekolah_strafikasi);
            jumlah_sekolah = parseInt(jumlah_sekolah) + parseInt(element.jumlah_sekolah);
        }

        setTotal({
            sekolah_strafikasi: sekolah_strafikasi,
            jumlah_sekolah: jumlah_sekolah,
            sekolah_sisa: (jumlah_sekolah - sekolah_strafikasi),
            persentase: ((sekolah_strafikasi / jumlah_sekolah) * 100).toFixed(2),
        });
    }

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "rotate(180deg)" : "rotate(0)",
        ...iconStyles,
    }

    return (
        <BaseLayout
            title={''}
            breadcrumb={[
                { label: "HOME", route: "/" },
                { label: 'Stratifikasi UKS', route: "/stratifikasi" },
                { label: loading ? "Memuat..." : 'Indonesia', },
            ]}
        >
            <MKBox
                width="100%"
                position="relative"
                borderRadius="xl"
                shadow="lg"
                mb={12}
                sx={{ overflow: "hidden" }}
            >
                <MKBox
                    px={3}
                    sx={{
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                    }}
                >
                    <Grid container spacing={2} justifyContent="space-between" py={1}>
                        <Grid item xs={12} lg={12}>
                            <MKTypography variant="body1" pt={0.5}>
                                Tabel Indeks Layanan Dasar Akses Sanitasi Sekolah (Akses Air, Sanitasi, dan Kebersihan)
                            </MKTypography>
                            {/* add button link  */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <MKButton fullWidth target="_blank" href="https://sarpras.dikdasmen.kemdikbud.go.id/index.php/chome/index_sanitasi" variant="gradient" color="info" size="small" sx={{ textTransform: 'none !important', fontSize: '12px' }}>sumber data: https://sarpras.dikdasmen.kemdikbud.go.id</MKButton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <MKButton variant="gradient" color="warning" onClick={openDropdown} fullWidth={true} size="small">
                                        {selected || 'Filter Provinsi'} <Icon sx={dropdownIconStyles}>expand_more</Icon>
                                    </MKButton>
                                    <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown} sx={{ maxHeight: '300px' }}>
                                        <MenuItem onClick={e => { setSelected(''); closeDropdown() }}>Semua</MenuItem>
                                        {
                                            dataListFilter.map((opt, key) =>
                                                <MenuItem key={key} value={opt.provinsi} onClick={e => { setSelected(opt.provinsi); closeDropdown() }}>{opt.provinsi}</MenuItem>
                                            )
                                        }
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MKBox>
                <MKBox>
                    <MKBox width="100%" p={3}>
                        <MKBox
                            width="100%"
                            // maxHeight="40rem"
                            borderRadius="xl"
                        >
                            <TableContainer sx={{ maxHeight: "560px" }}>
                                <Table size="small" stickyHeader>
                                    <TableHead sx={{ display: 'table-header-group' }}>
                                        <TableRow>
                                            <TableCell width={"5%"} align="center">
                                                <MKTypography variant="caption" fontWeight={'bold'}>No</MKTypography>
                                            </TableCell>
                                            <TableCell width={"15%"} align="left">
                                                <MKTypography variant="caption" fontWeight={'bold'}>Provinsi</MKTypography>
                                            </TableCell>
                                            <TableCell width={"15%"} align="left">
                                                <MKTypography variant="caption" fontWeight={'bold'}>Kabupaten</MKTypography>
                                            </TableCell>

                                            <TableCell width={"15%"} align="right">
                                                <MKTypography variant="caption" fontWeight={'bold'}>Ganjil 2022</MKTypography>
                                            </TableCell>

                                            <TableCell width={"15%"} align="right">
                                                <MKTypography variant="caption" fontWeight={'bold'}>Genap 2021</MKTypography>
                                            </TableCell>

                                            <TableCell width={"15%"} align="right">
                                                <MKTypography variant="caption" fontWeight={'bold'}>Ganjil 2021</MKTypography>
                                            </TableCell>

                                            <TableCell width={"15%"} align="right">
                                                <MKTypography variant="caption" fontWeight={'bold'}>Genap 2020</MKTypography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dataList
                                                .filter((opt) => {
                                                    if (selected === '') {
                                                        return opt;
                                                    } else if (opt.provinsi === selected) {
                                                        return opt;
                                                    }
                                                })
                                                .sort((a, b) => b.ganjil_2022 - a.ganjil_2022)
                                                .map((opt, key) => {
                                                    const { ganjil_2022, genap_2021, ganjil_2021, genap_2020 } = opt;

                                                    const getIconHasil = (value1, value2) => {
                                                        if (value1 < value2) {
                                                            return <ArrowDownward fontSize="small" sx={{ color: "#f44336", display: 'inline-block', verticalAlign: 'sub', fontSize: `${isMobile && "1em !important"}` }} />;
                                                        } else if (value1 > value2) {
                                                            return <ArrowUpward fontSize="small" sx={{ color: "#42cb09", display: 'inline-block', verticalAlign: 'sub', fontSize: `${isMobile && "1em !important"}` }} />;
                                                        }
                                                        return <Remove fontSize="small" sx={{ display: 'inline-block', verticalAlign: 'sub', fontSize: `${isMobile && "1em !important"}` }} />;
                                                    };

                                                    let hasil_ganjil_2022_genap_2021 = getIconHasil(ganjil_2022, genap_2021);
                                                    let hasil_genap_2021_ganjil_2021 = getIconHasil(genap_2021, ganjil_2021);
                                                    let hasil_ganjil_2021_genap_2020 = getIconHasil(ganjil_2021, genap_2020);

                                                    return (
                                                        <StyledTableRow key={key} hover>
                                                            <TableCell width={"5%"} align="center">
                                                                <MKTypography variant="caption">{key + 1}</MKTypography>
                                                            </TableCell>
                                                            <TableCell width={"15%"} align="left">
                                                                <MKTypography variant="caption">{(opt.provinsi)}</MKTypography>
                                                            </TableCell>
                                                            <TableCell width={"15%"} align="left">
                                                                <MKTypography variant="caption">{(opt.kabupaten)}</MKTypography>
                                                            </TableCell>
                                                            <TableCell width={"15%"} align="right">
                                                                <MKTypography variant="caption"><b>{(opt.ganjil_2022)}</b> {hasil_ganjil_2022_genap_2021}</MKTypography>
                                                            </TableCell>
                                                            <TableCell width={"15%"} align="right">
                                                                <MKTypography variant="caption"><b>{(opt.genap_2021)}</b> {hasil_genap_2021_ganjil_2021}</MKTypography>
                                                            </TableCell>
                                                            <TableCell width={"15%"} align="right">
                                                                <MKTypography variant="caption"><b>{(opt.ganjil_2021)}</b> {hasil_ganjil_2021_genap_2020}</MKTypography>
                                                            </TableCell>
                                                            <TableCell width={"15%"} align="right">
                                                                <MKTypography variant="caption"><b>{(opt.genap_2020)}</b></MKTypography>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    )
                                                })
                                        }
                                        {/* <TableRow>
                                                <TableCell width={"15%"} align="left" colSpan={2}>
                                                    <MKTypography variant="caption" fontWeight={'bold'}>Total</MKTypography>
                                                </TableCell>
                                                <TableCell width={"15%"} align="right">
                                                    <MKTypography variant="caption" fontWeight={'bold'}>{ parseFloat(total.persentase).toFixed(2) }%</MKTypography>
                                                </TableCell>
                                                <TableCell width={"15%"} align="right">
                                                    <MKTypography variant="caption" fontWeight={'bold'}>{ formatNumber(total.jumlah_sekolah) }</MKTypography>
                                                </TableCell>
                                                <TableCell width={"15%"} align="right">
                                                    <MKTypography variant="caption" fontWeight={'bold'}>{ formatNumber(total.sekolah_strafikasi) }</MKTypography>
                                                </TableCell>
                                                <TableCell width={"15%"} align="right">
                                                    <MKTypography variant="caption" fontWeight={'bold'}>{ formatNumber(total.sekolah_sisa) }</MKTypography>
                                                </TableCell>
                                            </TableRow> */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MKBox>
                    </MKBox>
                </MKBox>
            </MKBox>
        </BaseLayout>
    )
}

export default Stratifikasiukskab;