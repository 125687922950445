const headerExcel = (labelExcel) => (
  `<table>
      <tbody>
        <tr>
          <td colSpan="27">
            <b>${labelExcel}</b>
          </td>
        </tr>

        <tr>
          <td colSpan="27">
            <b></b>
          </td>
        </tr>

        <tr>
          <td colSpan="27">&nbsp;</td>
        </tr>
      </tbody>
    </table>`
);

function generateTableForExcel(tableId, labelExcel) {
  const containerH = document.createElement('div');
  const header = containerH;
  containerH.innerHTML = headerExcel(labelExcel);

  const table = document.getElementById(tableId);
  const newTable = table.cloneNode(true);
  newTable.setAttribute('border', '1');

  const rows = newTable.childNodes[1];

  if (rows.hasChildNodes()) {
    rows.childNodes.forEach((row) => {
      const nip = row.childNodes[3];
      nip?.setAttribute('style', "mso-number-format: '0'");
    });
  }

  const content = document.createElement('div');
  content.appendChild(header);
  content.appendChild(newTable);

  return content;
}

export default generateTableForExcel;
