/* eslint-disable */
import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
// import routes from "routes";
import Home from "pages/UKS/Home";
// import BeritaDetail from "pages/UKS/Halaman/BeritaDetail";
// import Berita from "pages/UKS/Halaman/Berita";
import Comingson from "pages/UKS/comingson";
// import TentangUKS from "pages/UKS/Halaman/Tentanguks";
// import Stratifikasiuks from "pages/UKS/Halaman/Stratifikasiuks";
// import PraktikbaikDetail from "pages/UKS/Halaman/Praktikbaik/PraktikbaikDetail";
import routesList from "routesList";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.route} />;
      }

      return null;
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* <Route path="/halaman/berita/:page" element={<Berita/>} /> */}
        {/* <Route path="/halaman/berita-detail/:slug" element={<BeritaDetail />} /> */}
        {/* <Route path="/halaman/tentang-uks/:slug" element={<TentangUKS />} /> */}
        {/* <Route path="/informasi/stratifikasi/:id_level_wilayah/:kode_wilayah" element={<Stratifikasiuks/>} /> */}
        {/* <Route path="/informasi/praktik-baik-detail/:slug" element={<PraktikbaikDetail />} /> */}
        <Route path="/home" element={<Home />} />
        {getRoutes(routesList)}
        <Route path="/:id/:id/:id" element={<Comingson/>} />
        <Route path="/:id/:id" element={<Comingson/>} />
        <Route path="/:id" element={<Comingson/>} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </ThemeProvider>
  );
}
