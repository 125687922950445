/* eslint-disable */
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import ViewPage from "layouts/sections/components/ViwePage";
import { useParams } from "react-router-dom";


function Program() {
    const { slug } = useParams();
    const [program, setProgram] = useState([]);
    const [label, setLabel] = useState([]);
    const [loading, setLoading] = useState(true);
    // const slug = window.location.pathname.replace("/program/", '');

    useEffect(() => {
        setLoading(true);
        axios({
            url: `/get_program_detail/${slug}`,
            method: "GET"
        }).then((e) => {
            if(e.data.rows){
                setProgram(e.data.rows);
                setLoading(false);
            }else{
                setProgram([]);
                setLoading(false);   
            }
        })
    }, [slug]);

    return (
        <BaseLayout 
            title={ loading ? "Memuat..." : program.judul || 'Segera Hadir' }
            breadcrumb={[
                { label: "HOME", route: "/" },
                { label: loading ? "Memuat..." : program.judul || 'Segera Hadir' }
            ]}
            >
                <ViewPage title="" tabs={label} active={program}>                    
                    <p>Memuat...</p>
                </ViewPage>
        </BaseLayout>
    )
}

export default Program;