/* eslint-disable */
import { Divider, Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalTeamCard2 from "examples/Cards/TeamCards/HorizontalTeamCard2";

function AktifitasMitraDetail() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [ aktifitas_mitra, setAktifitasMitra] = useState([]);
    const [ aktifitasMitraSort, setAktifitasMitraSort] = useState([]);
    const [ loading, setLoading] = useState(true);
    const [ loading2, setLoading2] = useState(true);
    const { slug } = useParams();

    useEffect(() => {
        getAktifitasMitra();
        getAktifitasMitraSort();
    }, [slug]);

    const getAktifitasMitra = () => {
        axios({
            url: `/get_aktifitas_mitra_detail/${slug}`,
            method: "GET"
        }).then((e) => {
            setAktifitasMitra(e.data.rows);
            setLoading(false);
        })
    }

    const getAktifitasMitraSort = () => {
        axios({
            url: `/get_aktifitas_mitra/1?limit=5`,
            method: "GET"
        }).then((e) => {
            setAktifitasMitraSort(e.data.rows);
            setLoading2(false);
        })
    }

    const handleDate = (value) => {
        if(value){
            const time = new Date(value);
            const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

            return `${time.getDate()} ${bulan[parseInt(time.getMonth())]} ${time.getFullYear()}`;
        }
    }

    return (
        <BaseLayout 
            title={''}
            breadcrumb={[
                { label: "Aktifitas Mitra", route: "/halaman/aktifitas-mitra" },
                { label: loading ? 'Memuat...' : aktifitas_mitra.judul || '' }
            ]}
        >            
            <Grid container spacing={2} mb={12}>
                <Grid item xs={12} sm={9}>
                    <MKBox
                        width="100%"
                        position="relative"
                        borderRadius="xl"
                        shadow="lg"
                        sx={{ overflow: "hidden" }}
                    >
                        <MKBox display={"block"}>
                            <MKBox width="100%" p={1.5}>
                                <MKBox
                                    width="100%"
                                    borderRadius="xl"
                                    sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                                >                            
                                    <MKTypography variant="h5" style={{ fontSize: 19 }}>{ aktifitas_mitra.judul || (<Skeleton variant="text" height={50} />) }</MKTypography>
                                    <div  sx={{ mb: 3 }}>
                                        <MKTypography variant="caption">
                                            Kategori : <b>{aktifitas_mitra.categori}</b>
                                            &nbsp;|&nbsp;
                                            Tanggal : <b>{handleDate(aktifitas_mitra.publis_date)}</b>
                                            &nbsp;|&nbsp;
                                            Tanggal Kegiatan : <b>{handleDate(aktifitas_mitra.start_date)} s/d {handleDate(aktifitas_mitra.end_date)}</b>
                                        </MKTypography>
                                    </div>
                                    <Divider/>
                                    {!loading ? (
                                        <div dangerouslySetInnerHTML={{__html: fullScreen ? (aktifitas_mitra.konten || '').replace(/style="height: auto;width: auto/g, 'style="height: 100%;width: 100%"') : (aktifitas_mitra.konten || '') }} />  
                                    ) : (
                                        <Skeleton variant="rectangular" height={500} />
                                    )}
                                    <Divider/>
                                    <div>
                                        <MKTypography variant="caption">Kategori : <b>{aktifitas_mitra.categori}</b>&nbsp;|&nbsp;Tanggal : <b>{handleDate(aktifitas_mitra.publis_date)}</b></MKTypography>
                                    </div>
                                </MKBox>
                            </MKBox>
                        </MKBox>
                    </MKBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {
                        loading2 && [1, 2, 3, 4, 5].map((skl, keySkl) => <div key={keySkl} style={{ marginBottom: 10 }}><Skeleton variant="rectangular" height={100} /></div>)
                    }
                    {
                        !loading2 &&  aktifitasMitraSort.map((opt, key) =>
                            <MKBox mb={1} key={key}>
                                <HorizontalTeamCard2
                                    image={ opt.thumbnail }
                                    name={ opt.title }
                                    link={`/aktifitas-mitra/${opt.slug}`}
                                    date={opt.publis_date}
                                />
                            </MKBox>
                        )
                    }
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default AktifitasMitraDetail;