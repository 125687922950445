/* eslint-disable */
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import ViewPage from "layouts/sections/components/ViwePage";
import { useParams } from "react-router-dom";


function GalaKreasi() {
    const { slug } = useParams();
    const [gala_kreasi, setGalaKreasi] = useState({id:'-', konten: ''});
    const [label, setLabel] = useState([{value: '-', label:'...GalaKreasi...'}]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios({
            url: `/get_gala_kreasi/${slug}`,
            method: "GET"
        }).then((e) => {
            if(e.data.rows){
                setGalaKreasi(e.data.rows);
                setLabel(e.data.label);
                setLoading(false);
            }else{
                setGalaKreasi([]);
                setLabel(e.data.label);
                setLoading(false);
            }
        })
    }, [slug]);

    return (
        <BaseLayout 
            title={gala_kreasi.judul || 'Memuat...'}
            breadcrumb={[
                { label: "HOME", route: "/" },
                { label: loading ? "Memuat..." : gala_kreasi.judul }
            ]}
            >
                <ViewPage title="" tabs={label} active={gala_kreasi} slug="sekolah-sehat" header='tutup'>
                    <p>Memuat...</p>
                </ViewPage>
        </BaseLayout>
    )
}

export default GalaKreasi;