/* eslint-disable */
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TableInfoCard from "examples/Cards/InfoCards/TableInfoCard";
import { Link } from "@mui/material";

// Icon
import BgImageSarpras from 'assets/images/sarpras-dikdasmen.kemdikbud.jpg';
import BgImageSarprasPropinsi from 'assets/images/sarpras-dikdasmen_provinsi.kemdikbud.jpeg';
// import BgImageDapo from 'assets/images/dapodik.jpg';
import BhSanitasiSekolah from 'assets/images/SanitasiSekolah2.jpg';
import ExampleCard from "pages/UKS/Home/components/ExampleCard";

function Information(props) {
  const { rekapWilayah } = props;

  return (
    <MKBox component="section">
      <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
        <MKTypography variant="h4" mb={5} mt={2}>
          <center>
            Indeks SDGs Layanan Dasar Akses Sanitasi Sekolah<br/>
            (Air, Sanitasi, dan Kebersihan)
          </center>
        </MKTypography>
      </Grid>

      <Grid container spacing={1}>
        <Grid item container spacing={1} xs={12} xl={7}>
          <Grid item xs={12} sm={6}>
            <TableInfoCard
              variant="gradient"
              color="light"
              icon=""
              title={(<>10 Teratas Propinsi Layanan Dasar<br/>Akses Sanitasi Sekolah</>)}
              description="Semester 2021/2022 Ganjil"
              table={rekapWilayah.propinsi}
              action={{
                type: "internal",
                route: "/stratifikasi",
                label: "Data Selengkapnya",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableInfoCard
              variant="gradient"
              color="light"
              icon=""
              title={(<>10 Teratas Kabupaten/Kota Layanan Dasar<br/>Akses Sanitasi Sekolah</>)}
              description="Semester 2021/2022 Ganjil"
              table={rekapWilayah.kabupaten}
              action={{
                type: "internal",
                route: "/stratifikasi-kab",
                label: "Data Selengkapnya",
              }}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} xl={5}>
          <Grid item xs={12} md={12} sx={{ mb: { xs: 1.5, lg: 0 } }}>
            <MKBox
              variant="gradient"
              bgColor="light"
              borderRadius="xl"
              py={2}
              px={1}
            >
              <Link target="_blank" href={"https://sarpras.dikdasmen.kemdikbud.go.id/"}>
                <ExampleCard image={BgImageSarpras} name={""} display="grid" maxHeight={{ lg: '230px', xl: '230px' }} />
              </Link>
              <MKButton fullWidth={true} sx={{ marginTop: 1 }} color="success" size="small" href="https://sarpras.dikdasmen.kemdikbud.go.id/" target="_blank">Data Nasional</MKButton>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: { xs: 1.5, lg: 0 } }}>
            <MKBox
              variant="gradient"
              bgColor="light"
              borderRadius="xl"
              py={2}
              px={1}
            >
              <Link target="_blank" href={"https://sarpras.dikdasmen.kemdikbud.go.id/index.php/chome/provinsi?kode_wilayah=010000"}>
                <ExampleCard image={BgImageSarprasPropinsi} name={""} display="grid" maxHeight={{ lg: '230px', xl: '230px' }}/>
              </Link>
              <MKButton fullWidth={true} sx={{ marginTop: 1 }} color="success" size="small" href="https://sarpras.dikdasmen.kemdikbud.go.id/index.php/chome/provinsi?kode_wilayah=010000" target="_blank">Data Provinsi</MKButton>
            </MKBox>
          </Grid>
          {/* <Grid item container spacing={1} xs={12} md={12} sx={{ mb: { xs: 3, lg: 3 }, mt: { xs: 1, lg: 1 } }}>
            <Grid item xs={6} md={6}>
              <MKButton fullWidth={true} color="success" size="small" href="https://sarpras.dikdasmen.kemdikbud.go.id/" target="_blank">Data Nasional</MKButton>
            </Grid>
            <Grid item xs={6} md={6}>
              <MKButton fullWidth={true} color="success" size="small" href="https://sarpras.dikdasmen.kemdikbud.go.id/index.php/chome/provinsi?kode_wilayah=010000" target="_blank">Data Propinsi</MKButton>
            </Grid>
            <Grid item xs={4} md={4}>
              <MKButton fullWidth={true} color="success" size="small" href="https://sarpras.dikdasmen.kemdikbud.go.id/dev/index.php/chome/detailkabupaten?kode_wilayah=016000&npsn=" target="_blank">Data Kabupaten</MKButton>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={{ lg: 0.5 }}>
        <Grid item xs={12} sm={7} order={{ xs: 2 }}>
          <MKBox
            variant="gradient"
            bgColor="light"
            borderRadius="xl"
            minHeight="280px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            py={4}
            px={1}
          >
            <MKTypography variant="h4">
              <center>Penjelasan Indeks SDGs</center>
            </MKTypography>
            <MKTypography variant="body">
              <center style={{ padding: "0 2rem", fontSize: 15 }}>Indeks ini bertujuan untuk memenuhi target Tujuan Pembangunan Berkelanjutan (SDGs) pada tahun 2030. Beberapa indikator utama SDGs 4 terkait Pendidikan, yaitu layanan dasar akses air, akses sanitasi, dan akses kebersihan (WASH) di sekolah.<br/>Data di atas menunjukkan persentase pemenuhan sekolah terhadap layanan dasar akses air, sanitasi dan kebersihan yang telah dipenuhi ketiga unsurnya terhadap total populasi sekolah di wilayah tersebut untuk jenjang PAUD, dikdas, dikmen, diksus, dan dikmas (PAUD, SD, SMP, SMA, SMK, PKBM, SKB, SLB).</center>
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={12} sm={5} order={{ lg: 2 }}>
          <MKBox
            variant="gradient"
            bgColor="light"
            borderRadius="xl"
            minHeight="280px"
            py={2}
            px={1}
          >
            <Link target="_blank" href={"https://spasial.data.kemdikbud.go.id/sanitasi/"}>
              <ExampleCard image={BhSanitasiSekolah} name={""} display="grid" minHeight="auto" />
              <MKButton fullWidth={true} sx={{ marginTop: 1 }} color="success" size="small" href="https://spasial.data.kemdikbud.go.id/sanitasi/" target="_blank">Data Spasial Sanitasi</MKButton>
            </Link>
          </MKBox>
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Information;
