const baseUrl = () => {
    let link;
    switch (window.location.hostname) {
        case 'uks.mardawa.id': link = 'https://beuks.mardawa.id/'; break;
        case 'uks.kemdikbud.go.id': link = 'https://api-uks.kemdikbud.go.id/'; break;
    
        default:
            link = 'https://api-uks.kemdikbud.go.id/';
            // link = 'http://localhost:8000/';
            break;
    }

    return link;
}

const AppConfig = {
    judul: 'UKS',
    sub_judul: 'Usaha Kesehatan Sekolah',
    client: 'Kegiatan yang dilakukan untuk meningkatkan kesehatan anak usia sekolah/madrasah pada setiap jalur, jenis, dan jenjang pendidikan',
    sub_sub_judul : 'Sumber: PB 4 Menteri tentang Pembinaan dan Pengembangan UKS/M Tahun 2014',
    kode_wilayah: '000000',
    link_api : baseUrl(),
    link_files : baseUrl()
}

export default AppConfig;