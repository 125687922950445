/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";
// import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from "react";
import { Card, CardContent, CardMedia, Dialog, Divider, IconButton, Typography } from "@mui/material";
import EventIcon from '@mui/icons-material/Event';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import AppsIcon from '@mui/icons-material/Apps';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import AppConfig from "AppConfig";

function Infografis({ infografis }) {
    const [rows, setRows] = useState(infografis);
    const [open, setOpen] = useState(false);
    const [embed, setEmbed] = useState([]);

    useEffect(() => {
        setRows(infografis);
    }, [infografis])

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    const handleOpen = (opt) => {
        const slideImages = opt.files.map((opt) => { return {url: opt.path.includes(AppConfig.link_api) ? opt.path : `https://is3.cloudhost.id/storagedirectus1/${opt.path}`, caption: opt.nama}});
        setOpen(true);
        setEmbed(slideImages);
    }
  
    return (
    <MKBox component="section" py={2}>
        <Container sx={{ pl: '0px', pr: '0px' }}>
            <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
                <MKTypography variant="h3" mb={4}>
                    Infografis
                </MKTypography>
            </Grid>
            <Grid container spacing={3} justifyContent="center">
                {rows.map((opt, key) => {    
                    const slideImages = opt.files.map((opt) => { return {url: opt.path.includes(AppConfig.link_api) ? opt.path : `https://is3.cloudhost.id/storagedirectus1/${opt.path}`, caption: opt.nama}});

                    return (
                        <Grid key={key} item xs={12} sm={6} md={6} lg={3}>
                            <Card>
                                {slideImages.length === 1 && (
                                    <CardMedia
                                        component="img"
                                        height="370"
                                        width="100%"
                                        image={slideImages[0].url}
                                        style={{
                                            margin: 0,
                                            width: '100%'
                                        }}
                                        alt={opt.nama}
                                    />
                                )}
                                {slideImages.length > 1 && (
                                    <div className="slide-container">
                                        <Slide>
                                            {slideImages.map((slideImage, index)=> (
                                                <div className="each-slide" key={index}>
                                                    <div style={{'backgroundImage': `url(${slideImage.url})`, height: 370, backgroundSize: 'cover', borderRadius: '0.75rem', backgroundPosition: 'center'}}>
                                                    </div>
                                                </div>
                                            ))} 
                                        </Slide>
                                    </div>
                                )}
                                <CardContent style={{ padding: '12px 20px' }}>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            fontSize: 16,
                                            minHeight: "50px",
                                        }}
                                    >
                                        { opt.nama }
                                    </Typography>
                                    <Divider style={{ margin: 2, marginTop: 7 }}/>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '8px' }}>
                                        <IconButton size="small" color="success" onClick={() => handleOpen(opt)}>
                                            <ZoomInIcon /><p style={{ fontSize:'13px' }}>Perbesar</p>
                                        </IconButton>
                                        <Typography variant="caption" color="text.secondary">
                                            <div style={{ display: 'flex', alignItems: 'center' }}> <EventIcon/>&nbsp;{handleTanggal(opt.create_date)}</div>
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                    }) }
            </Grid>
            <Grid container style={{ marginTop: '1.4rem' }}>
                <MKButton                    
                    component={MuiLink}
                    href={'/dokumen/publikasi/infografis'}
                    rel="noreferrer"
                    variant="gradient"
                    size="medium"
                    color="success"
                    fullWidth={true}
                    sx={{fontSize:"14px"}}
                >
                    <AppsIcon/>&nbsp;
                    Semua Infografis
                </MKButton>
            </Grid>
        </Container>

        <Dialog
            open={open}
            maxWidth="xl"
            fullWidth={true}
            onClose={() => setOpen(false)}
        >            
            <Grid spacing={1}>
                <Grid item sm={12} sx={{ display:'flex' }} justifyContent="flex-end">
                    <IconButton sx={{ padding: '10px 15px' }} size="small" color="error" onClick={() => setOpen(false)}><CloseIcon fontSize="medium"/> Tutup</IconButton>
                </Grid>
                <Grid item sm={12}>
                    {embed.map((opt, key) => <img key={key} src={opt.url} width="100%" style={{ marginBottom: 12 }} />)}
                </Grid>
            </Grid>
        </Dialog>
    </MKBox>
    );
}

export default Infografis;
