/* eslint-disable */
import BaseLayout from "layouts/sections/components/BaseLayout";

function Comingson() {

    return (
        <BaseLayout
            title="Menu dalam pengembangan"
            breadcrumb={[
                { label: "Segera Hadir" },
            ]}
            >            
        </BaseLayout>
    )
}

export default Comingson;