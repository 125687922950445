/* eslint-disable */
import { Box, Divider, Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalTeamCard2 from "examples/Cards/TeamCards/HorizontalTeamCard2";

function BeritaDetail() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [berita, setBerita] = useState([]);
    const [beritaSort, setBeritaSort] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const { slug } = useParams();

    useEffect(() => {
        getBerita();
        getBeritaSort();
    }, []);

    const getBerita = () => {
        axios({
            url: `/get_berita_detail/${slug}`,
            method: "GET"
        }).then((e) => {
            setBerita(e.data);
            setLoading(false);
        })
    }

    const getBeritaSort = () => {
        axios({
            url: `/get_berita/1?limit=5`,
            method: "GET"
        }).then((e) => {
            setBeritaSort(e.data.rows);
            setLoading2(false);
        })
    }

    const handleDate = (value) => {
        if(value){
            const time = new Date(value);
            const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

            return `${time.getDate()} ${bulan[parseInt(time.getMonth())]} ${time.getFullYear()}`;
        }
    }

    return (
        <BaseLayout 
            title={''}
            breadcrumb={[
                { label: "Berita", route: "/halaman/berita" },
                { label: loading ? 'Memuat...' : berita.judul || '' }
            ]}
        >            
            <Grid container spacing={2} mb={12}>
                <Grid item xs={12} sm={9}>
                    <MKBox
                        width="100%"
                        position="relative"
                        borderRadius="xl"
                        shadow="lg"
                        sx={{ overflow: "hidden" }}
                    >
                        <MKBox display={"block"}>
                            <MKBox width="100%" p={1.5}>
                                <MKBox
                                    width="100%"
                                    borderRadius="xl"
                                    sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                                >                            
                                    <MKTypography variant="h5" style={{ fontSize: 19 }}>{ berita.judul || (<Skeleton variant="text" height={50} />) }</MKTypography>
                                    <div  sx={{ mb: 3 }}>
                                        <MKTypography variant="caption">Kategori : <b>{berita.kategori_berita}</b>&nbsp;|&nbsp;Tanggal : <b>{handleDate(berita.tanggal_publis)}</b></MKTypography>
                                    </div>
                                    <Divider/>
                                    {!loading ? (
                                        <Box
                                            sx={{
                                                "img" :{
                                                    borderRadius: "10px",
                                                    width: "100%",
                                                    height: "auto"
                                                }
                                            }}
                                            dangerouslySetInnerHTML={{__html: fullScreen ? (berita.konten_berita || '').replace(/style="height: auto;width: auto/g, 'style="height: 100%;width: 100%"') : (berita.konten_berita || '') }}
                                        />  
                                    ) : (
                                        <Skeleton variant="rectangular" height={500} />
                                    )}
                                    <Divider/>
                                    <div>
                                        <MKTypography variant="caption">Kategori : <b>{berita.kategori_berita}</b>&nbsp;|&nbsp;Tanggal : <b>{handleDate(berita.tanggal_publis)}</b></MKTypography>
                                    </div>
                                </MKBox>
                            </MKBox>
                        </MKBox>
                    </MKBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {
                        loading2 && [1, 2, 3, 4, 5].map((skl, keySkl) => <div key={keySkl} style={{ marginBottom: 10 }}><Skeleton variant="rectangular" height={100} /></div>)
                    }
                    {
                        !loading2 && beritaSort.map((opt, key) =>
                            <MKBox mb={1} key={key}>
                                <HorizontalTeamCard2
                                    image={ opt.images }
                                    name={ opt.judul }
                                    link={`/halaman/berita-detail/${opt.slug}`}
                                    date={opt.tanggal_publis}
                                />
                            </MKBox>
                        )
                    }
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default BeritaDetail;