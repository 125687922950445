/* eslint-disable */
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import routes from "routes";
// import footerRoutes from "footer.routes";
// import UKSFooter from "examples/Footers/UKSFooter";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Badge, Checkbox, Chip, Icon, List, ListItem, ListItemText } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";

function FAQ() {
    const [expanded, setExpanded] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [checked, setChecked] = React.useState([]);
    const [dataKategori, setDataKategori] = useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    useEffect(() => {
        getKategori();
    }, []);

    useEffect(() => {
        getfaq();
    }, [checked]);

    const getKategori = () => {
        axios({
            url: '/getKategorifaq',
            method: 'GET',
        }).then((e) => {
            setDataKategori(e.data.rows);
            setChecked(e.data.selected);
            setLoading2(false);
        })
    }

    const getfaq = (cari) => {
        setLoading(true);
        axios({
            url: `/getfaq?cari=${cari || ''}&kategori=${JSON.stringify(checked)}`,
            method: 'GET',
        }).then((e) => {
            setData(e.data.rows);
            setLoading(false);
        })
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const handleChangeCari = (event) => {
        const { value } = event.target;

        setSearchText(value);
    }

    const pencarian = () => {
        getfaq(searchText);
    }

    return (
    <>
        {/* <MKBox position="fixed" top="0.5rem" width="100%">
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "external",
                    route: "https://www.creative-tim.com/product/material-kit-react",
                    label: "free download",
                    color: "info",
                }}
            />
        </MKBox> */}
        <BaseLayout 
            title={""}
            breadcrumb={[
                { label: "HOME", route: "/" },
                { label: "FAQ" }
            ]}
            >
        <Grid container spacing={2} mt={1} mb={4}>
            <Grid item xs={12} lg={4}>
                <MKBox
                    bgColor="white"
                    borderRadius="xl"
                    shadow="lg"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <MKBox
                        variant="gradient"
                        bgColor="success"
                        coloredShadow="success"
                        borderRadius="lg"
                        p={1}
                        mx={2}
                        mt={-3}
                    >
                        <MKTypography variant="h6" color="white">
                            Kategori
                        </MKTypography>
                    </MKBox>
                    <MKBox p={3}>
                        <MKBox width="100%">
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {
                                    dataKategori.map((opt, key) => {
                                        const labelId = `checkbox-list-secondary-label-${opt.nama}`;

                                        return (
                                            <ListItem
                                                labe
                                                // secondary={5}
                                                secondaryAction={
                                                    <>
                                                    <Badge
                                                        badgeContent={opt.count}
                                                        color="secondary"
                                                    />
                                                    &nbsp;&nbsp;
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={handleToggle(parseInt(opt.kategori_faq_id))}
                                                        checked={checked.indexOf(parseInt(opt.kategori_faq_id)) !== -1}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                    </>
                                                }
                                            >
                                                <ListItemText
                                                    id={labelId}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                            >
                                                            { opt.nama }
                                                            </Typography>
                                                            {/* {` — ${opt.count} Item`} */}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </MKBox>
                    </MKBox>
                </MKBox>
            </Grid>
            <Grid item xs={12} lg={8}>
                <MKBox
                    bgColor="white"
                    borderRadius="xl"
                    shadow="lg"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <MKBox
                        variant="gradient"
                        bgColor="success"
                        coloredShadow="success"
                        borderRadius="lg"
                        p={1}
                        mx={2}
                        mt={-3}
                    >
                        <MKTypography variant="h6" color="white">
                            Frequently Asked Questions
                        </MKTypography>
                    </MKBox>
                    <MKBox p={3} style={{ minHeight: '20vh' }}>
                        <MKBox width="100%">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={10}>
                                    <MKInput
                                        variant="standard"
                                        label="Pencarian"
                                        value={searchText}
                                        // InputLabelProps={{ shrink: true }}
                                        onChange={handleChangeCari}
                                        placeholder="Ketik kata kunci untuk pencarian..."
                                        onKeyPress={(e) => {
                                            if(e.charCode === 13){
                                                pencarian()
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <MKButton type="button" variant="gradient" size="small" color="success" style={{ width: '100%' }} onClick={pencarian}>
                                        <Icon fontSize="small">search</Icon>&nbsp;Cari
                                    </MKButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={5} mb={2}>
                                <div>
                                    {
                                        !loading && data.map((opt, key) => {

                                            return (
                                                <Accordion key={key} expanded={expanded === opt.faq_id} onChange={handleChange(opt.faq_id)} style={{ width: '100%' }}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                    <Typography variant="body2" sx={{ width: '90%', flexShrink: 0 }}>
                                                        { opt.pertanyaan }
                                                    </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style={{ padding: '1rem' }}>
                                                            <Typography variant="body2">
                                                                <div dangerouslySetInnerHTML={{__html: opt.jawaban || ''}} />
                                                            </Typography>
                                                            <Typography variant="caption">Kategori: <Chip size="small" label={ opt.kategori } /></Typography>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })
                                    }
                                </div>
                            </Grid>
                        </MKBox>
                    </MKBox>
                </MKBox>
            </Grid>
        </Grid>
        </BaseLayout>
    </>
    );
}

export default FAQ;
