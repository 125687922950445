/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from "react";
import DokumenCard from "examples/Cards/BlogCards/DokumenCard";
import AppsIcon from '@mui/icons-material/Apps';

function BukuPanduan({ bukupanduan }) {
    const [rows, setRows] = useState(bukupanduan);

    useEffect(() => {
        setRows(bukupanduan);
    }, [bukupanduan])

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }
  
    return (
        <MKBox component="section" py={2}>
        <Container sx={{ pl: '0px', pr: '0px' }}>
            <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
                <MKTypography variant="h3" mb={6}>
                    Buku Panduan
                </MKTypography>
            </Grid>
            <Grid container spacing={3}>
            {
                rows.map((opt, key) => {
                    const link = opt.files.length !== 0 ? opt.files[0].path : '';

                    return (
                        <Grid item xs={12} md={6} lg={3} key={key}>
                            <DokumenCard
                                align="left"
                                image={ opt.cover ? opt.cover.includes('api-uks.kemdikbud.go.id') ? opt.cover : `https://is3.cloudhost.id/storagedirectus1/${opt.cover}`:"/ilustrasi-dokumen.png"}
                                title={opt.nama}
                                rows={opt}
                                action={{
                                    type: "external",
                                    nama: opt.nama,
                                    route: link,
                                    key: key,
                                    fileId: opt.publikasi_id,
                                    color: "success",
                                    label: "Unduh",
                                }}
                            />
                        </Grid>
                    )
                })
            }
            </Grid>
            <Grid container>
                <MKButton
                    style={{ marginTop: '1.4rem' }}
                    component={MuiLink}
                    href={'/dokumen/publikasi/buku-panduan'}
                    rel="noreferrer"
                    variant="gradient"
                    size="medium"
                    color="success"
                    fullWidth={true}
                    sx={{fontSize:"14px"}}
                >
                    <AppsIcon/>&nbsp;
                    Semua Buku Panduan
                </MKButton>
            </Grid>
        </Container>
        </MKBox>
    );
}

export default BukuPanduan;
