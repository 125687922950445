/* eslint-disable */
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import ViewPage from "layouts/sections/components/ViwePage";
import { useParams } from "react-router-dom";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import BannerBimtek from 'assets/images/BIMTEK-Landing_Page_SEKOLAH_DARING.png';
import BannerBimtek2 from 'assets/images/BIMTEK-Landing_Page_UKS-Modul.png';


function Bimtek() {
    const { slug } = useParams();
    const [program, setProgram] = useState([]);
    const [label, setLabel] = useState([]);
    const [loading, setLoading] = useState(false);

    return (
        <BaseLayout 
            title={ loading ? "Memuat..." : "Bimtek Daring Sekolah Sehat ( Telah dirilis tanggal 6 Oktober 2022 )" }
            breadcrumb={[
                { label: "HOME", route: "/" },
                { label: loading ? "Memuat..." : "Bimtek Daring" }
            ]}
            >
            <MKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                // display="flex"
                // flexDirection="column"
                justifyContent="center"
                padding="28px 36px 36px"
                marginBottom="96px"
            >
                <Grid container sx={{ mb: "1.5rem" }}>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Box component="img" sx={{ height: 200 }} alt="BannerBimtek" src={BannerBimtek} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Box component="img" sx={{ height: 200 }} alt="BannerBimtek_2" src={BannerBimtek2} />
                    </Grid>
                </Grid>
            <MKTypography variant="h5">Judul Bimtek</MKTypography>
            <MKTypography variant="body2"mb={2}>Bimtek Daring Terbuka Masal “Mewujudkan Sekolah Sehat yang Tangguh di Indonesia”</MKTypography>
            <MKTypography variant="h5">Penjelasan Singkat Bimtek</MKTypography>
            <MKTypography variant="body2" mb={2}>Mewujudkan sekolah sehat dengan pembiasaan dan penerapan perilaku hidup bersih dan sehat (PHBS)</MKTypography>
            <MKTypography variant="h5" >Deskripsi Bimtek</MKTypography>
            <MKTypography variant="body2" mb={2}>
                Tidak dapat dipungkiri bahwa kebiasaan hidup bersih dan sehat menjadi hal yang mendasar bagi para warga negara. Hal ini juga menjadi prioritas bagi para warga sekolah untuk menjaga agar roda pengetahuan dan pendidikan berjalan tanpa hambatan. Bimtek daring terbuka masal “Mewujudkan Sekolah Sehat yang Tangguh di Indonesia” ini merupakan sebuah perwujudan dalam menggapai mimpi tersebut.
                <br/>
                <br/>
                Peserta akan mendapatkan latar belakang pelaksanaan Program Usaha Kesehatan Sekolah/Madrasah (UKS/M) untuk mewujudkan Sekolah Sehat, dan bagaimana perilaku hidup bersih dan sehat (PHBS) yang tepat untuk mewujudkan Sekolah Sehat. Peserta juga akan mengetahui cara melakukan Persiapan dan Penyusunan Rencana Kegiatan dan Anggaran Sekolah (RKAS) untuk kegiatan sekolah sehat, serta bagaimana menjalin kerja sama dengan Pemangku Kepentingan dan mitra potensial terkait implementasi kegiatan Program Sekolah Sehat. Di akhir peserta juga akan mendapatkan gambaran bagaimana menggunakan Projek Penguatan Profil Pelajar Pancasila (P5) dalam kaitannya dengan rencana tindak lanjut kegiatan Sekolah Sehat.
            </MKTypography>
            <MKTypography variant="h5" style={{marginBottom: 10}}>Apa yang akan Anda Pelajari</MKTypography>
            <MKTypography variant="body2" mb={2}>
                <strong>Modul Pembuka: Pengantar Bimtek Daring “Mewujudkan Sekolah Sehat yang Tangguh di Indonesia”</strong> 
                <ol style={{ marginLeft: 30, marginBottom: 10 }}>
                    <li>Latar belakang Gerakan Sekolah Sehat</li>
                    <li>Gambaran umum keseluruhan modul 1-5</li>
                    <li>Cara menavigasi platform bimtek daring</li>
                </ol>
                <strong>Modul Pertama: Peran Program Usaha Kesehatan Sekolah/Madrasah (UKS/M) untuk Mewujudkan Sekolah Sehat</strong> 
                <ol style={{ marginLeft: 30, marginBottom: 10  }}>
                    <li>Memahami tentang latar belakang dan pentingnya penerapan UKS/M termasuk Trias UKS/M dan Manajemen UKS/M</li>
                    <li>Mendapatkan motivasi untuk melaksanakan program UKS/M</li>
                    <li>Langkah-langkah pelaksanaan program UKS/M.</li>
                </ol>
                <strong>Modul Kedua: Pembiasaan Perilaku Hidup Bersih dan Sehat (PHBS) yang Tepat untuk Mewujudkan Sekolah Sehat</strong> 
                <ol style={{ marginLeft: 30, marginBottom: 10 }}>
                    <li>Menjelaskan pentingnya penerapan PHBS di sekolah untuk mewujudkan Sekolah Sehat yang Tangguh</li>
                    <li>Memahami tindakan apa yang perlu diambil untuk meningkatkan penerapan PHBS yang tepat</li>
                </ol>
                <strong>Modul Ketiga: Persiapan dan Penyusunan Rencana Kegiatan dan Anggaran Sekolah (RKAS) untuk Kegiatan Sekolah Sehat</strong> 
                <ol style={{ marginLeft: 30, marginBottom: 10 }}>
                    <li>Memahami peran Kepala Sekolah dan tim pelaksana UKS/M dalam pelaksanaan dan penyusunan RKAS terkait kegiatan Sekolah Sehat</li>
                    <li>Mampu membuat rencana dan anggaran pelaksanaan kegiatan Sekolah Sehat termasuk program terkait UKS/M dan PHBS yang dituangkan dalam RKAS</li>
                    <li>Menggunakan perangkat yang mendukung penyusunan rencana dan anggaran kesiapan sekolah dengan lebih percaya diri</li>
                </ol>
                <strong>Modul Keempat: Menjalin Kerja Sama dengan Pemangku Kepentingan dan Mitra Potensial Terkait Implementasi Kegiatan Sekolah Sehat</strong> 
                <ol style={{ marginLeft: 30, marginBottom: 10 }}>
                    <li>Menjelaskan pentingnya kemitraan dengan pemangku kepentingan yang relevan dalam penerapan Sekolah Sehat</li>
                    <li>Menjelaskan pihak-pihak yang bisa diajak bermitra dan berkolaborasi</li>
                    <li>Menjelaskan hal-hal yang perlu diperhatikan untuk menjaga hubungan baik dengan mitra</li>
                </ol>
                <strong>Modul Kelima: Rencana Tindak Lanjut Kegiatan Sekolah Sehat berbasis Projek Penguatan Profil Pelajar Pancasila (P5)</strong> 
                <ol style={{ marginLeft: 30, marginBottom: 10 }}>
                    <li>Membuat program Projek Penguatan Profil Pelajar Pancasila dengan tema Gaya Hidup Berkelanjutan secara lengkap dengan jadwal dan rubrik penilaian</li>
                    <li>Membuat program Projek Penguatan Profil Pelajar Pancasila dengan tema Bangunlah Jiwa dan Raganya secara lengkap dengan jadwal dan rubrik penilaian</li>
                </ol>
            </MKTypography>
            <MKTypography variant="h5">Kebutuhan sebelum Belajar</MKTypography>
            <MKTypography variant="body2" mb={2}>Komputer/ Laptop, Jaringan Internet yang Baik, dan Telepon Pintar</MKTypography>
            <hr style={{ margin:"1rem 0" }}/>
            <MKTypography variant="h5">Panduan Penggunaan Bimtek Daring Sekolah Sehat</MKTypography>
            <MKTypography variant="body2" mb={2}>
                <ol style={{ marginLeft: 30, marginBottom: 10 }}>
                    <li>Masuk laman <a href="https://uks.kemdikbud.go.id">uks.kemdikbud.go.id</a></li>
                    <li>Pilih Menu Bimtek Daring</li>
                    <li>Baca dengan seksama Penjelasan Bimtek Daring</li>
                    <li>Klik Tombol "Masuk ke Bimtek Daring Sekolah Sehat"</li>
                    <li>Klik Tautan "Buat Akun Baru"</li>
                    <li>Lengkapi biodata peserta (alamat email harus aktif)</li>
                    <li>Cek email lalu konfirmasi keanggotaan pada tautan yang dikirimkan</li>
                    <li>Klik Lanjutkan untuk memulai Bimtek Daring Sekolah Sehat</li>
                </ol>
            </MKTypography>
            <MKButton
                color="success"
                variant="contained"
                size="medium"
                href="https://uks.kemdikbud.go.id/lms/"
                target="_blank"
            ><LoginIcon/>&nbsp;&nbsp;<p style={{ fontSize: 14 }}>Masuk ke Bimtek Daring Sekolah Sehat</p></MKButton>
            </MKBox>
        </BaseLayout>
    )
}

export default Bimtek;