/* eslint-disable */
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Icon
import BgImagesOKY from 'assets/images/products/app-oky.jpg';
// import BgImagesQROKY from 'assets/images/products/qr_oky.png';
import BgImagesQROKY from 'assets/images/products/qr_oky_baru.jpeg';
import { Typography } from "@mui/material";

function Aplikasi(props) {
    const { aplikasi } = props;
    const layout = 2; // 1,2,3

    return (
        <MKBox component="section">
            <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
                <MKTypography variant="h3" mb={3} mt={2}>
                    Aplikasi Terkait UKS/M
                </MKTypography>
            </Grid>
            <Grid container justifyContent="center" mx="auto">
                <Grid item xs={0} sm={0}></Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                    {
                        aplikasi.map((row, keyApp) => {
                            if(layout === 1){
                                return (
                                    <Grid item container xs={12} md={12} sx={{ mb: 2 }} key={keyApp} style={{ border: '1px solid silver', padding: 10 }}>
                                        <Grid item sm={12}>
                                            <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
                                            <MKTypography variant="h3" mb={3} mt={2} style={{ display: 'flex', alignItems: 'center'}}>
                                                <img alt="#" height={"100px"} src={row.logo} /> { row.nama }
                                            </MKTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6}>
                                        <img alt="#" height={"250px"} src={BgImagesOKY} />
                                        <img alt="#" height={"250px"} src={BgImagesQROKY} />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Typography variant="body1" style={{ fontSize: '0.9rem !important', lineHeight: '0.7' }}>
                                                Oky adalah aplikasi seluler untuk membantu remaja perempuan melacak menstruasinya dan mendapatkan edukasi terkait menstruasi dan kesehatan reproduksi remaja dengan cara yang menyenangkan. <br/>
                                                Aplikasi ini dikembangkan bersama anak  perempuan dan untuk anak perempuan (usia 10+). Oky menggunakan desain teknologi inovatif untuk mengatasi masalah terkait tabu, stigma negatif, kesalahpahaman, dan kurangnya informasi berkualitas terkait menstruasi dan kesehatan reproduksi anak perempuan. <br/>
                                                Unduh sekarang dan lihat bagaimana Oky dapat membuat menstruasi Anda bebas stres!
                                            </Typography>
                                            <MKButton color="success" href={row.link} target="_blank" fullWidth={true}>Unduh Sekarang</MKButton>
                                        </Grid>
                                    </Grid>
                                )
                            }else if(layout === 2){
                                return (
                                    <MKBox
                                        component="section"
                                        bgColor="white"
                                        borderRadius="xl"
                                        shadow="lg"
                                        minHeight="10rem"
                                        key={keyApp}
                                    >
                                    <Grid item container spacing={1} xs={12} md={12} sx={{ mb: 2, p: 2 }}>
                                        <Grid item container spacing={1} sm={12}>
                                            <Grid item sm={12}>
                                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                                    <img alt="#" height={"90px"} src={row.logo} />
                                                    <div>
                                                        <MKTypography variant="h5">{ row.nama }</MKTypography>
                                                        <MKTypography variant="caption">{ row.client }</MKTypography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item sm={3}>
                                                <div style={{ width: '100%', overflow: 'auto' }}>
                                                    <img alt="#" height={"200px"} src={BgImagesOKY} />
                                                </div>
                                            </Grid>
                                            <Grid item sm={7}>
                                                <Typography style={{ fontSize: 13, fontWeight: 0, textIndent: 20, lineHeight: 2 }}>
                                                    Oky adalah aplikasi seluler untuk membantu remaja perempuan melacak menstruasinya dan mendapatkan edukasi terkait menstruasi dan kesehatan reproduksi remaja dengan cara yang menyenangkan. <br/>
                                                    Aplikasi ini dikembangkan bersama anak  perempuan dan untuk anak perempuan (usia 10+). Oky menggunakan desain teknologi inovatif untuk mengatasi masalah terkait tabu, stigma negatif, kesalahpahaman, dan kurangnya informasi berkualitas terkait menstruasi dan kesehatan reproduksi anak perempuan. <br/>
                                                    Unduh sekarang dan lihat bagaimana Oky dapat membuat menstruasi Anda bebas stres!
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={2} xs={12}>
                                                <div style={{ alignContent: 'center' }}>
                                                    <center>
                                                        <img alt="#" height={"130px"} src={BgImagesQROKY} />
                                                        <MKButton color="success" href={row.link} target="_blank" fullWidth={true} size="small" >Unduh Sekarang</MKButton>
                                                    </center>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </MKBox>
                                )
                            }else{
                                return "";
                            }
                        })
                    }
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0}></Grid>
            </Grid>
        </MKBox>
    );
}

export default Aplikasi;
