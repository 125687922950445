/* eslint-disable */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import Logo from "assets/images/logos/uks_logo.png";
import Logo from "assets/images/logos/logo_uks_asli.png";
import { Box, Divider, Icon, Typography } from "@mui/material";
import "./style.css";

function UKSFooter() {
  const [getVisitor, setGetVisitor] = useState([]);
  useEffect(() => {
    axios.get('/api/getVisitor').then((res) => {
      setGetVisitor(res.data)
    })
  }, [])

  const menus = [
    {
      name: 'Kontak',
      items: [
        {
          icon: 'business',
          name: 'Direktorat Jenderal PAUD, Dikdas dan Dikmen',
          route: '',
          href: '',
          textTransform: true,
        },
        // {
        //     icon: 'location_on',
        //     name: '',
        //     route: '',
        //     href: '',
        //     textTransform: true,
        // },
        {
          icon: 'location_on',
          name: 'Gedung E lantai 14 Kompleks Kemendikbudristek, Jalan Jenderal Sudirman, Senayan, Jakarta 10270',
          route: '',
          href: '',
          textTransform: true,
        },
        {
          icon: 'language',
          name: 'https://pauddikdasmen.kemdikbud.go.id',
          route: '',
          href: 'https://pauddikdasmen.kemdikbud.go.id',
          textTransform: false,
        },
        {
          icon: 'language',
          name: 'https://uks.kemdikbud.go.id',
          route: '',
          href: 'https://uks.kemdikbud.go.id',
          textTransform: false,
        },
        {
          icon: 'email',
          name: 'uks.dikdasmen@kemdikbud.go.id',
          route: '',
          href: '',
          textTransform: false,
        }
      ]
    }
  ]

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            <MKBox>
              <MKBox component="img" src={Logo} alt={'UKS'} maxWidth="5rem" mb={2} />
              <MKTypography variant="h6">Sekretariat UKS/M</MKTypography>
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={12} md={6} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="none"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, icon, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="none"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Icon>{icon}</Icon>&nbsp;{name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="none"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Icon>{icon}</Icon>&nbsp;{name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            <Box className="footer-wrap">
              <Box>
                <Typography
                  variant="body1"
                  fontSize="12px"
                >
                  Statistik Kunjungan
                </Typography>
                <Typography
                  variant="h5"
                  fontSize="16px"
                  marginTop="-4px"
                  marginBottom="4px"
                >
                  Portal UKS/M
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" marginTop="8px">
                <Typography fontSize="12px">
                  Pengunjung Hari Ini
                </Typography>
                <Typography fontSize="12px">
                  {getVisitor?.pengunjung_hariini}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Typography fontSize="12px">
                  Dikunjungi Hari Ini
                </Typography>
                <Typography fontSize="12px">
                  {getVisitor?.dikunjungi_hariini}
                </Typography>
              </Box>
              <Divider style={{ borderColor: "#ffffff61", margin: 0 }} />
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Typography fontSize="12px">
                  Pengunjung Bulan Ini
                </Typography>
                <Typography fontSize="12px">
                  {getVisitor?.pengunjung_bulanini}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Typography fontSize="12px">
                  Dikunjungi Bulan Ini
                </Typography>
                <Typography fontSize="12px">
                  {getVisitor?.dikunjungi_bulanini}
                </Typography>
              </Box>
              <Divider style={{ borderColor: "#ffffff61", margin: 0 }} />
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Typography fontSize="12px">
                  Total Pengunjung
                </Typography>
                <Typography fontSize="12px">
                  {getVisitor?.pengunjung}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" >
                <Typography fontSize="12px">
                  Total Dikunjungi
                </Typography>
                <Typography fontSize="12px">
                  {getVisitor?.dikunjungi}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography variant="button" fontWeight="regular">
              Didukung Oleh: Kemdikbudristek & GIZ &copy;2022
            </MKTypography>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default UKSFooter;
