/* eslint-disable */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import colors from "assets/theme/base/colors";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from "@mui/material";
import Information from "pages/UKS/Halaman/Program/information";

function ViewPage({ children, tabs, title, height, active, slug, header, ...rest }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { id, konten } = active;
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(id);
  const [success, setSuccess] = useState(false);

  const toolbar = header === 'tutup' ? false : true;

  const handleTabType = (event, newValue) => {
    navigate(`/${slug || 'tentang-uks'}/${newValue}`);
  }

  useEffect(() => {
    setTimeout(() => setSuccess(false), 3000);
  }, [success]);

  useEffect(() => {
    setActiveTab(id)
  }, [active]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      {toolbar && (
        <MKBox
          px={3}
          sx={{
            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <Grid container spacing={2} justifyContent="space-between" py={1}>
            <Grid item xs={0} lg={0}>
              <MKTypography variant="body1" pt={0.5}>
                {title}
              </MKTypography>
            </Grid>
            {tabs.length !== 0 && (
              <Grid item xs={12} lg={12} style={{ display: (fullScreen ? 'none' : '') }}>
                <AppBar position="static">
                  <Tabs
                    value={activeTab}
                    onChange={handleTabType}
                    aria-label="scrollable auto tabs example"
                  >
                    {
                      tabs.map((opt, keytab) =>
                        <Tab key={keytab} value={opt.value} label={opt.label} style={{ fontSize: 13 }} />
                      )
                    }
                  </Tabs>
                </AppBar>
              </Grid>
            )}
          </Grid>
        </MKBox>
      )}
      <MKBox display={"block"}>
        <MKBox width="100%" p={1.5}>
          <MKBox
            width="100%"
            borderRadius="xl"
            style={{ fontSize: '1rem' }}
            sx={{
              pl: fullScreen ? 1 : 3,
              pr: fullScreen ? 1 : 3,
              pt: 2,
              pb: 3,
              "& > * ol, & > * ul": {
                paddingInlineStart: '3ch'
              }
            }}
          >
            {Array.isArray(konten) && <Information konten={konten} />}

            {!Array.isArray(konten) && !fullScreen && <div dangerouslySetInnerHTML={{ __html: (konten || '').replaceAll('<p></p>', '<br/>') }} />}
            {!Array.isArray(konten) && fullScreen && <div dangerouslySetInnerHTML={{ __html: (konten || '').replace(/style="height: auto;width: auto/g, 'style="height: 100%;width: 100%"') }} />}
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

ViewPage.defaultProps = {
  height: "auto",
};

ViewPage.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default ViewPage;
