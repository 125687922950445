import Berita from "pages/UKS/Halaman/Berita";
import BeritaDetail from "pages/UKS/Halaman/BeritaDetail";
import Stratifikasiuks from "pages/UKS/Halaman/Stratifikasiuks";
import TentangUKS from "pages/UKS/Halaman/Tentanguks";
import FAQ from "pages/UKS/Halaman/FAQ/faq";
import Agenda from "pages/UKS/Halaman/Agenda";
import ProdukHukum from 'pages/UKS/Halaman/ProdukHukum';
import Praktikbaik from 'pages/UKS/Halaman/Praktikbaik';
import PraktikbaikDetail from "pages/UKS/Halaman/Praktikbaik/PraktikbaikDetail";
import Program from "pages/UKS/Halaman/Program";
import AgendaDetail from "pages/UKS/Halaman/Agenda/detail";
import Kebijakan from "pages/UKS/Halaman/Kebijakan";
// import Publikasi from "pages/UKS/Halaman/Publikasi";
import Aplikasi from "pages/UKS/Halaman/Aplikasi";
import AplikasiDetail from "pages/UKS/Halaman/Aplikasi/detail";
import BukuPanduan from "pages/UKS/Halaman/Publikasi/buku-panduan";
import Video from "pages/UKS/Halaman/Publikasi/video";
import Infografis from "pages/UKS/Halaman/Publikasi/infografis";
import Bimtek from "pages/UKS/Halaman/BimtekDaring/bimtek";
import SekolahSehat from "pages/UKS/Halaman/SekolahSehat";
import Stratifikasiukskab from "pages/UKS/Halaman/Stratifikasiuks/indexKab";
import ProdukHukumNew from "pages/UKS/Halaman/ProdukHukumCopy";
import IframePage from "pages/UKS/Halaman/SekolahSehat/iframe";
import IframeGalaKreasi from "pages/UKS/Halaman/SekolahSehat/IframeGalaKreasi";
import Mitra from "pages/UKS/Halaman/Mitra/Mitra";
import AktifitasMitra from "pages/UKS/Halaman/Mitra/AktifitasMitra";
import AktifitasMitraDetail from "pages/UKS/Halaman/Mitra/AktifitasMitraDetail";
import GalaKreasi from "pages/UKS/Halaman/GalaKreasi";
import SekolahBinaan from "pages/UKS/Halaman/SekolahBinaan";

const routesList = [
    {
        route: "/tentang-uks/:slug",
        component: <TentangUKS/>
    },
    {
        route: "/program/:slug",
        component: <Program/>
    },
    {
        route: "/halaman/berita/:page",
        component: <Berita/>
    },
    {
        route: "/halaman/berita",
        component: <Berita/>
    },
    {
        route: "/halaman/berita-detail/:slug",
        component: <BeritaDetail/>
    },
    {
        route: "/halaman/faq",
        component: <FAQ/>
    },
    {
        route: "/bimtek",
        component: <Bimtek/>
    },
    {
        route: "/informasi/praktik-baik",
        component: <Praktikbaik/>
    },
    {
        route: "/informasi/praktik-baik/:page",
        component: <Praktikbaik/>
    },
    {
        route: "/informasi/agenda/:page",
        component: <Agenda/>
    },
    {
        route: "/informasi/agenda",
        component: <Agenda/>
    },
    {
        route: "/informasi/aplikasi:page",
        component: <Aplikasi/>
    },
    {
        route: "/informasi/aplikasi",
        component: <Aplikasi/>
    },
    {
        route: "/informasi/aplikasi-detail/:aplikasi_id",
        component: <AplikasiDetail/>
    },
    {
        route: "/informasi/agenda-detail/:agenda_id",
        component: <AgendaDetail />
    },
    {
        route: "/informasi/praktik-baik-detail/:slug",
        component: <PraktikbaikDetail/>
    },
    {
        route: "/dokumen/produk-hukum",
        component: <ProdukHukum/>
    },
    {
        route: "/dokumen/produk-hukum-new",
        component: <ProdukHukumNew/>
    },
    {
        route: "/dokumen/produk-hukum-new/:kode_wilayah",
        component: <ProdukHukumNew/>
    },
    {
        route: "/dokumen/produk-hukum-new/:kode_wilayah/:files",
        component: <ProdukHukumNew/>
    },
    {
        route: "/dokumen/kebijakan",
        component: <Kebijakan/>
    },
    {
        route: "/dokumen/publikasi/buku-panduan/:page",
        component: <BukuPanduan/>
    },
    {
        route: "/dokumen/publikasi/buku-panduan",
        component: <BukuPanduan/>
    },
    {
        route: "/dokumen/publikasi/infografis/:page",
        component: <Infografis/>
    },
    {
        route: "/dokumen/publikasi/infografis/detail/:publikasiId",
        component: <Infografis/>
    },
    {
        route: "/dokumen/publikasi/infografis",
        component: <Infografis/>
    },
    {
        route: "/dokumen/publikasi/video/:page",
        component: <Video/>
    },
    {
        route: "/dokumen/publikasi/video",
        component: <Video/>
    },
    {
        route: "/stratifikasi",
        component: <Stratifikasiuks/>
    },
    {
        route: "/stratifikasi/:id_level_wilayah/:kode_wilayah",
        component: <Stratifikasiuks/>
    },
    {
        route: "/stratifikasi-kab",
        component: <Stratifikasiukskab/>
    },
    {
        route: "/sekolah-sehat/:slug",
        component: <SekolahSehat/>
    },
    // {
    //     route: "/sekolah-sehat",
    //     component: <SekolahSehat/>
    // },
    {
        route: "/sekolah-sehat",
        component: <IframePage/>
    },
    {
        route: "/galakreasivideo",
        component: <IframeGalaKreasi />
    },
    {
        route: "/mitra/:slug",
        component: <Mitra/>
    },
    {
        route: "/aktifitas-mitra/:slug",
        component: <AktifitasMitraDetail/>
    },
    {
        route: "/aktifitas-mitra",
        component: <AktifitasMitra/>
    },
    {
        route: "/gala-kreasi/:slug",
        component: <GalaKreasi/>
    },
    {
        route: "/sekolah-binaan/:slug",
        component: <SekolahBinaan/>
    }
    
];

export default routesList;