/* eslint-disable */
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

function IframeGalaKreasi() {
    const [isLoading, setIsLoading] = useState(true);

    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        const allTagA = document.querySelectorAll('a');
        document.title = "UKS | Gala Kreasi Video";
        document.body.style.overflow = "hidden";
        allTagA.forEach((tagA) => {
            tagA.setAttribute('target', '_blank');
        });
    }, []);

    return (
        <>
            {isLoading && (
                <div className="splash">
                    <div
                        style={{
                            display: 'flex',
                            height: '100vh',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '290px',
                                overflow: 'hidden',
                                height: '320px'
                            }}
                        >
                            <img style={{ marginLeft: '-20px' }} src="https://uks.kemdikbud.go.id/static/media/uks_logo_length_03.4f7eb0c8.png" alt="Logo" />
                        </div>
                        <div>
                            <h1
                                style={{
                                    textAlign: 'center',
                                    lineHeight: '1',
                                    fontSize: '26px',
                                }}
                            >
                                Memuat Halaman <br /> Gala Kreasi Video
                            </h1>
                            <Box
                                id='spinner'
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    margin: "20px auto",
                                    width: "56px",
                                    "> div": {
                                        width: '12px',
                                        height: '12px',
                                        backgroundColor: '#0d9247',
                                        borderRadius: '100%',
                                        display: 'inline-block',
                                        WebkitAnimation: 'bounch 1s infinite ease-in-out both',
                                        animation: 'bounch 1s infinite ease-in-out both'
                                    },
                                    "& .bounce1": {
                                        WebkitAnimationDelay: '-0.32s',
                                        animationDelay: '-0.32s',
                                    },
                                    "& .bounce2": {
                                        WebkitAnimationDelay: '-0.16s',
                                        animationDelay: '-0.16s',
                                    },
                                    "@keyframes bounch": {
                                        "0%, 80%, 100%": {
                                            WebkitTransform: 'scale(0)',
                                            transform: 'scale(0)',
                                        },
                                        "40%": {
                                            WebkitTransform: 'scale(1)',
                                            transform: 'scale(1)',
                                        },
                                    },
                                }}
                            >
                                <div className='bounce1'></div>
                                <div className='bounce2'></div>
                                <div className='bounce3'></div>
                            </Box>
                        </div>
                    </div>
                </div >
            )}
            <iframe
                title="gala-kreasi-video"
                src="https://ditpsd.kemdikbud.go.id/kss/gala-kreasi-video/"
                width="100%"
                height="100%"
                // loading="lazy"
                onLoad={handleIframeLoad}
                onError={handleIframeLoad}
                // sandbox="allow-scripts allow-same-origin allow-presentation allow-popups allow-modals allow-top-navigation-by-user-activation"
                style={{
                    border: '0',
                    height: '100vh'
                }}
            />
        </>
    )
}

export default IframeGalaKreasi;
