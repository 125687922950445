/* eslint-disable */
import { Chip, Divider, Grid, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, Icon, Menu, MenuItem, ListItemAvatar, Link, IconButton, Avatar, Button, Card, CardContent } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { Pagination, Stack } from '@mui/material';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import SearchIcon from '@mui/icons-material/Search';
// import './style.css';

function CardApp({ opt }) {
    const navigate = useNavigate();

    return (
        <MKBox position="relative" borderRadius="lg" onClick={() => navigate(`/informasi/aplikasi-detail/${opt.aplikasi_id}`)}>
            <MKBox
                style={{
                    backgroundImage: `url(${opt.logo})`,
                    backgroundSize: 'contain'
                }}
                borderRadius="lg"
                width="80px"
                height="80px"
                position="relative"
                zIndex={1}
            />
            <MKBox
                borderRadius="lg"
                shadow="md"
                width="80px"
                height="80px"
                position="absolute"
                left={0}
                top={0}
                sx={{
                backgroundImage: `url(${opt.logo})`,
                transform: "scale(0.94)",
                filter: "blur(3px)",
                backgroundSize: "cover",
                }}
            />
        </MKBox>
    )
}

function Aplikasi() {
    const { page } = useParams();
    const navigate = useNavigate();
    const [aplikasi, setAplikasi] = useState([]);
    const [countPage, setCountPage] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [select, setSelect] = useState([]);
    const [selected, setSelected] = useState('');
    const [dropdown, setDropdown] = useState(null);
    const limitPage = 10;
    const thisPage = parseInt(page) || 1;

    useEffect(() => {
        setLoading(true);
        getData();
    }, [page]);

    const getData = () => {
        axios({
            url: `/get_aplikasi/${thisPage}?limit=${limitPage}&cari=${search}`,
            method: 'GET',
        }).then((e) => {            
            const { rows, count_all } = e.data;
            const data = Math.ceil(count_all/limitPage) || 1;
            setAplikasi(rows);
            setCountPage(data);
            setLoading(false);
        });
    }

    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    const handleChange = (event, value) => {
        navigate(`/informasi/aplikasi/${value}`)
    }

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    const handleClick = (opt) => {
        window.open(opt.link, "_blank");
    }

    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "rotate(180deg)" : "rotate(0)",
        ...iconStyles,
    }

    return (
        <BaseLayout
            title="Aplikasi"
            breadcrumb={[
                { label: "Home", route: "/" },
                { label: loading ? "Menuat..." : "Aplikasi" },
            ]}
            >
            <Grid container spacing={2} justifyItems="center" justifyContent="center" sx={{ mt: 1 }}>
                <Grid item xs={12} lg={10}>
                    <MKInput
                        variant="standard"
                        placeholder="Pencarian"
                        name='search'
                        value={search}
                        size="small"
                        onChange={e => {
                            setSearch(e.target.value)
                        }}
                        onKeyPress={(e) => {
                            if (e.charCode === 13) {
                                getData();
                            }
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>            
            <Grid container spacing={2} justifyContent="center" mx="auto" style={{ marginTop: '30px', marginBottom: '30px' }}>
                {
                    aplikasi.map((opt, key) => {

                        return (
                            <Grid item xs={4} md={1.6} key={key}>
                                <CardApp opt={opt}/>
                                <div style={{ marginTop: '0.5rem' }}>
                                    <MKTypography variant="h6" sx={{ m:0, fontSize: 14 }} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{opt.nama}</MKTypography>
                                    <MKTypography variant="caption" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{opt.client}</MKTypography><br/>
                                    <Chip label="Unduh" variant="outlined" color="success" size="small" onClick={() => handleClick(opt)} />
                                </div>
                            </Grid>
                        )
                    })
                }
                
            </Grid>
            <Grid container item justifyContent="center" xs={12} lg={6} mx="auto" height="100%" mt={2} mb={2} sx={{ mb: 10 }}>
                <Stack spacing={2}>
                    <Pagination
                        count={countPage}
                        defaultPage={thisPage}
                        color="secondary"
                        showFirstButton
                        showLastButton
                        onChange={handleChange}
                    />
                </Stack>
            </Grid>
        </BaseLayout>
    )
}

export default Aplikasi;