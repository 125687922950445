/* eslint-disable */
import { Chip, Divider, Grid, Card, CardContent, CardHeader, Skeleton, InputBase, Tab, Tabs, Link } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import BeritaCard from "examples/Cards/BlogCards/BeritaCard";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import { Pagination, Stack } from '@mui/material';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridView';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Calender from 'pages/UKS/Widgets/Calender/Calender';
import defaultNewsImg from 'assets/images/defaultNews.jpg';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    fontSize: '0.8rem',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        // width: '20ch',
        },
    },
  }));

function Agenda() {
    // const { page } = useParams();
    const navigate = useNavigate();
    const [berita, setPraktekBaik] = useState([]);
    const [countPage, setCountPage] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState('');
    const [activeTab, setActiveTab] = useState('list'); // grid,list
    const [dropdown, setDropdown] = useState(null);
    const [kategori, setKategori] = useState([]);
    const limitPage = 12;
    const [page, setPage] = useState(1);
    const thisPage = parseInt(page) || 1;

    const [ keyPress, setKeyPress ] = useState(Math.random());

    const handleChangeKeyPress = () => {
        setKeyPress(Math.random());
    }

    const tabs = [
        {
            value: 'grid',
            icon: <GridViewIcon/>,
            label: "Grid"
        },
        {
            value: 'list',
            icon: <ListAltIcon/>,
            label: "List"
        }
    ]

    useEffect(() => {
        setLoading(true);
        getData();
    }, [keyPress]);

    const getData = () => {
        axios({
            // url: `/get_praktekbaik/${thisPage}?limit=${limitPage}&cari=${search}&kategori_artikel_id=${selected}`,
            url: `/get_agenda/${page}?limit=${limitPage}&cari=${search}&jenis_agenda=${selected}`,
            method: 'GET',
        }).then((e) => {            
            const { rows, count_all, jenis_agenda:select } = e.data;
            const data = Math.ceil(count_all/limitPage);
            setPraktekBaik(rows);
            setCountPage(data);
            setLoading(false);
            setKategori(select)
        });
    }

    const handleClick = (value) => {
        if(value === 'semua'){
            setSelected('');
            setPage(1)
            handleChangeKeyPress()
        }else{
            setSelected(value);
            setPage(1)
            handleChangeKeyPress()
        }
    }

    const handleChange = (event, value) => {
        setPage(value);
        handleChangeKeyPress()
    }

    const handleTabType = (event, newValue) => {
        setActiveTab(newValue);
    }

    const handleDate = (value) => {
        if(value){
            const time = new Date(value);
            const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

            return `${time.getDate()} ${bulan[parseInt(time.getMonth())]} ${time.getFullYear()}`;
        }
    }

    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "rotate(180deg)" : "rotate(0)",
        ...iconStyles,
    }

    return (
        <BaseLayout
            title="Agenda"
            breadcrumb={[
                { label: "Home", route: "/" },
                { label: loading ? "Menuat..." : "Agenda" },
            ]}
            >
            <Grid container spacing={2} style={{marginBottom: '2rem' }}>
                <Grid item md={9}>
                    <Card>
                        <CardHeader/>
                        <CardContent>
                            {
                                loading && (
                                    <Grid container spacing={3}>
                                        {[1,2,3,4,5,6,7,8,9].map((opt, key) => <Grid key={key} item xs={12} sm={12} ><Skeleton variant="rectangular" width={"100%"} height={120} /></Grid>)}
                                    </Grid>
                                )
                            }
                            {
                                !loading && activeTab === 'grid' && <Grid item container spacing={3} xs={12} sm={12}>
                                    {
                                        berita.map((opt, key) => {
                                            const imgView = opt.file1 ? ('https://api-uks.kemdikbud.go.id/' + opt.file1.replace("public", "storage")) : defaultNewsImg;
                                            
                                            return (
                                                <Grid item key={key} xs={12} sm={6} lg={4}>
                                                    <BeritaCard
                                                        align="left"
                                                        image={imgView}
                                                        title={opt.nama}
                                                        description={opt.description}
                                                        description_view={'none'}
                                                        height={'270px'}
                                                        action={{
                                                            type: "internal",
                                                            route: `/informasi/agenda-detail/${opt.agenda_id}`,
                                                            color: "success",
                                                            label: "lebih lanjut",
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        )
                                    }
                                </Grid>
                            }
                            {
                                !loading && activeTab === 'list' && (
                                    <Grid item container spacing={1} xs={12} sm={12}>
                                        <Grid item xs={12} sm={12}>
                                                {
                                                    berita.map((opt, key) => {
                                                        const imgView = opt.file1 ? ('https://api-uks.kemdikbud.go.id/' + opt.file1.replace("public", "storage")) : defaultNewsImg;
                                                        
                                                        return (
                                                            <div key={key}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={5} md={2}>
                                                                        <MKBox
                                                                            style={{
                                                                                backgroundImage: `url(${imgView})`,
                                                                                backgroundSize: 'cover'
                                                                            }}
                                                                            alt={opt.title}
                                                                            width={'100%'}
                                                                            height={110}
                                                                            borderRadius="md"
                                                                            shadow="lg"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={7} md={10}>
                                                                        <Link href={`/informasi/agenda-detail/${opt.agenda_id}`}><MKTypography variant="h6" >{ opt.nama }</MKTypography></Link>
                                                                        <MKTypography variant="caption">Jenis : <b>{opt.jenis_agenda}</b>&nbsp;Tanggal : <b>{handleDate(opt.mulai)}</b> s/d <b>{handleDate(opt.selesai)}</b></MKTypography>
                                                                        <div style={{ fontSize: 12 }} dangerouslySetInnerHTML={{__html: opt.description + "..." }} />
                                                                        <Link href={`/informasi/agenda-detail/${opt.agenda_id}`}><b style={{ fontSize: 12 }}>(Baca Selengkapnya)</b></Link>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider/>
                                                            </div>
                                                        )
                                                    })
                                                }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <Grid container item justifyContent="center" xs={12} lg={6} mx="auto" height="100%">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={countPage}
                                        defaultPage={thisPage}
                                        color="secondary"
                                        showFirstButton
                                        showLastButton
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3}>
                    <Card style={{ marginBottom: '0.8rem' }}>
                        <CardContent style={{ padding: 8, backgroundColor: '#f8f9fa', borderRadius: 7 }}>
                            <Search className='items-center'>
                                <SearchIconWrapper>
                                    <SearchIcon fontSize='0.8rem'/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Pencarian..."
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyPress={e => {
                                        if(e.key === 'Enter'){
                                            handleSearch()
                                        }
                                    }}
                                />
                            </Search>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: '0.8rem' }}>
                        <Tabs value={activeTab} onChange={handleTabType} aria-label="scrollable auto tabs example">
                            {tabs.map((opt, keytab) => <Tab key={keytab} value={opt.value} icon={opt.icon} iconPosition="start" label={opt.label} />)}                
                        </Tabs>
                    </Card>
                    <Card style={{ marginBottom: '0.8rem' }}>
                        <CardHeader title="Kategori" style={{ padding: 8 }}/>
                        <CardContent style={{ padding: 8 }}>
                            <Chip
                                variant={ selected === '' ? "filled" : "outlined"}
                                color="success"
                                size="small"
                                label="Semua"
                                onClick={e => handleClick('semua')}
                                style={{
                                    marginRight: 3,
                                    color: (selected === '' ? "#fff" : "#4caf50")
                                }}
                            />
                            {
                                kategori.map((opt, key) => {
                                    const sel = selected === opt.nama;
                                    
                                    return (
                                        <Chip
                                            key={key}
                                            variant={ sel ? "filled" : "outlined" }
                                            color="success"
                                            size="small"
                                            label={opt.nama}
                                            style={{
                                                marginRight: 3,
                                                color: (sel ? '#fff' : '#4caf50')
                                            }}
                                            onClick={e => handleClick(opt.nama)}
                                        />
                                    )
                                })
                            }
                        </CardContent>
                    </Card>
                    <Calender/>
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default Agenda;