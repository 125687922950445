/* eslint-disable */
import { Grid } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalTeamCard2 from "examples/Cards/TeamCards/HorizontalTeamCard2";

function PraktikbaikDetail() {
    const [data, setData] = useState([]);
    const [dataSort, setDataSort] = useState([]);
    const [loading, setLoading] = useState(true);
    const { slug } = useParams();

    useEffect(() => {
        getData();
        getDataSort();
    }, []);

    const getData = () => {
        axios({
            url: `/get_praktik_detail/${slug}`,
            method: "GET"
        }).then((e) => {
            setData(e.data);
            setLoading(false);
        })
    }

    const getDataSort = () => {
        axios({
            url: `/get_praktekbaik/1?limit=5`,
            method: "GET"
        }).then((e) => {
            setDataSort(e.data.rows);
            setLoading(false);
        })
    }

    return (
        <BaseLayout 
            title={''}
            breadcrumb={[
                { label: "Praktek Baik", route: "/informasi/praktik-baik" },
                { label: loading ? 'Memuat...' : data.judul || '' }
            ]}
        >            
            <Grid container spacing={2} mb={12}>
                <Grid item xs={12} sm={9}>
                    <MKBox
                        width="100%"
                        position="relative"
                        borderRadius="xl"
                        shadow="lg"
                        sx={{ overflow: "hidden" }}
                    >
                        <MKBox display={"block"}>
                            <MKBox width="100%" p={3}>
                                <MKBox
                                    width="100%"
                                    borderRadius="xl"
                                    sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                                >                            
                                    <MKTypography variant="h5" sx={{ mb: 3 }}>{ data.judul || '' }</MKTypography>
                                    {/* <img alt="" style={{ width: '400px', marginBottom: '0.3rem' }} src={data.images ? data.images : ''} /> */}
                                    <div dangerouslySetInnerHTML={{__html: (data.konten || '').replace(/style="height: auto;width: auto/g, 'style="height: 100%;width: 100%"') }} />
                                </MKBox>
                            </MKBox>
                        </MKBox>
                    </MKBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {
                        dataSort.map((opt, key) =>
                            <MKBox mb={1} key={key}>
                                <HorizontalTeamCard2
                                    image={ opt.images }
                                    name={ opt.judul }
                                    link={`/informasi/praktik-baik-detail/${opt.slug}`}
                                    date={"2022-03-26"}
                                />
                            </MKBox>
                        )
                    }
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default PraktikbaikDetail;