/* eslint-disable */
import { Box, Typography } from "@mui/material";

function BoxData(props) {
    const { title, totalData, totalPersenData, backgroundColor, color } = props;
    return (
        <Box backgroundColor={backgroundColor || '#fff'} border="1px solid #d1d1d1" borderRadius="5px" padding="10px 20px">
            <Typography variant="h6" height="55px" color={color} sx={{ textTransform: 'capitalize' }}>
                {title}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h1" color={color} sx={{ textTransform: 'capitalize' }}>
                    {totalData}
                </Typography>
                {totalPersenData && (
                    <Typography variant="h4" color={color} sx={{ textTransform: 'capitalize' }}>
                        ({totalPersenData})
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

export default BoxData;
