/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Card, Icon, Link, List, ListItem, Typography, CardActionArea, CardContent, CardMedia, Dialog, DialogContent, Divider, Box, useMediaQuery, useTheme } from "@mui/material";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";
import AppsIcon from '@mui/icons-material/Apps';
import EventIcon from '@mui/icons-material/Event';
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ForestIcon from '@mui/icons-material/Forest';
import { useEffect, useState } from "react";

function SekolahSehat({ mitra }) {

    const theme = useTheme();
    const MobileDown = useMediaQuery(theme.breakpoints.down('md'));
    const Desktop = useMediaQuery('max-width: 1440px');
    const DesktopUp = useMediaQuery(theme.breakpoints.up('xl'));
    const [embed, setEmbed] = useState('');
    const [open, setOpen] = useState(false);

    const heightMediaQuery = () => {
        if (MobileDown) {
            return "162px";
        } else if (DesktopUp) {
            return "205px";
        } else if (Desktop) {
            return "172px";
        } else {
            return "172px";
        }
    }

    const handleOpen = (opt) => {
        setOpen(true);
        setEmbed(opt.embed_youtube);
    }

    return (
        <MKBox component="section" py={2}>
            <Container sx={{ pl: '0px', pr: '0px' }}>
                <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
                    <MKTypography variant="h3" mb={1}>
                        Gerakan Sekolah Sehat
                    </MKTypography>
                </Grid>
                <Grid item container spacing={2} xs={12} md={12} sx={{ mb: 1, pt: 2, pb: 2 }}>
                    <Grid item xs={12} lg={12}>
                        <FilledInfoCard
                            height="18rem"
                            color="success"
                            icon="medical_services"
                            title="Fokus Sekolah Sehat"
                            description={(
                                <Grid container spacing={2}>
                                    <Grid item lg={4} md={4} mt={2}>
                                        <List
                                            sx={{
                                                alignItems: 'center',
                                                "> li:not(:last-child)": {
                                                    marginBottom: .9
                                                }
                                            }}
                                        >
                                            <ListItem><h2 style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}><RestaurantIcon />&nbsp;Sehat Bergizi</h2></ListItem>
                                            <ListItem><h2 style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}><DirectionsRunIcon />&nbsp;Sehat Fisik</h2></ListItem>
                                            <ListItem><h2 style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}><VaccinesIcon />&nbsp;Sehat Imunisasi</h2></ListItem>
                                            <ListItem><h2 style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}><VolunteerActivismIcon />&nbsp;Sehat Jiwa</h2></ListItem>
                                            <ListItem><h2 style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}><ForestIcon />&nbsp;Sehat Lingkungan</h2></ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item lg={8} md={8}>
                                        <p style={{ fontSize: 15, fontWeight:500, textAlign: "left", lineHeight: 1.625, }}>
                                            Berdasarkan hasil Riset Kesehatan Dasar (Riskesdas) Kementerian Kesehatan Tahun 2018, kondisi kesehatan pada usia anak sekolah dan remaja sangat mengkhawatirkan, khususnya terkait konsumsi makanan berisiko setiap hari, status gizi, kebersihan diri, dan aktivitas fisik.
                                            <br></br>
                                            Untuk itu, pemerintah, sekolah, dan masyarakat perlu bergotong royong untuk merevitalisasi Usaha Kesehatan Sekolah (UKS) yang berfokus pada pemenuhan gizi, olahraga (gerak badan), dan imunisasi lengkap sehingga peserta didik dapat mengikuti pembelajaran dengan optimal, seiring dimulainya kembali pembelajaran tatap muka.
                                        </p>
                                    </Grid>
                                </Grid>
                            )}
                            action={{
                                type: "button",
                                route: "/sekolah-sehat/gerakan-sekolah-sehat",
                                label: "Selengkapnya",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FilledInfoCard
                            height="auto"
                            color="success"
                            icon="school"
                            title="Mitra Sekolah Sehat"
                            description={(
                                <Grid container spacing={4} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sx={{ fontSize: 15, fontWeight:500, textAlign: "left", lineHeight: 1.625 }}>
                                        Dalam mendukung Revitalisasi UKS melalui Sekolah Sehat, Kemendikbudristek bermaksud untuk melakukan kolaborasi penta-helix dengan berbagai pemangku kepentingan (mitra) agar program ini menjadi sebuah Gerakan Bersama dan bukan program pemerintah semata.
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} justifyContent="left" justifyItems={"left"}>
                                            {
                                                mitra.map((row, key) => {
                                                    const imgView = row.logo ? row.logo : ("http://www.simply-chalets.co.uk/img/noimage.png")

                                                    return (
                                                        <Link key={key} target="_blank" href={row.link_web}>
                                                            <Card style={{ margin: 3, padding: 5 }}>
                                                                <img src={imgView} height={65} style={{ borderRadius: 5, }} />
                                                            </Card>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            action={{
                                type: "button",
                                route: "/sekolah-sehat/mitra-sekolah-sehat",
                                label: "Selengkapnya",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <FilledInfoCard
                            height="auto"
                            color="success"
                            icon="video_library"
                            title="Video Gerakan Sekolah Sehat"
                            description={(
                                <Box sx={{ display: 'flex', flexDirection: MobileDown ? 'column' : 'row' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Card>
                                                {/* <CardActionArea onClick={() => handleOpen({ embed_youtube: 'https://www.youtube.com/embed/Y0TtKL_T5UQ' })}> */}
                                                <CardActionArea onClick={() => handleOpen({ embed_youtube: 'https://www.youtube.com/embed/6UdVzFUQhXc' })}>
                                                    <CardMedia
                                                        component="img"
                                                        height="250"
                                                        width="100%"
                                                        // image="https://i.ytimg.com/vi/Y0TtKL_T5UQ/hqdefault.jpg"
                                                        image="https://i.ytimg.com/vi/6UdVzFUQhXc/hqdefault.jpg"
                                                        sx={{
                                                            margin: 0,
                                                            width: '100%',
                                                            "@media screen and (max-width: 375px) and (min-width: 0px)" :{
                                                                height: '142px'
                                                            },
                                                            "@media screen and (min-width: 376px)" : {
                                                                height: '162px'
                                                            },
                                                            "@media screen and (min-width : 600px)": {
                                                                height: '173px'
                                                            },
                                                            "@media screen and (min-width : 1440px)": {
                                                                height: '205px'
                                                            }
                                                        }}
                                                        alt="UKS"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div"
                                                            sx={{
                                                                fontSize: 15,
                                                                height: 40,
                                                                "@media screen and (max-width : 600px)": {
                                                                    overflow: 'hidden',
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: '2'
                                                                }
                                                            }}
                                                        >
                                                            {/* Webinar - Peluncuran BIMTEK Daring Mandiri Sekolah Sehat Pada Laman UKS/M */}
                                                            Gerakan Sekolah Sehat
                                                        </Typography>
                                                        <Divider />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <EventIcon />&nbsp;&nbsp;
                                                            {/* 6 Oktober 2022 */}
                                                            13 Juni 2024
                                                        </div>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Card>
                                                <CardActionArea onClick={() => handleOpen({ embed_youtube: 'https://www.youtube.com/embed/p3bck1lxCkU' })}>
                                                    <CardMedia
                                                        component="img"
                                                        height="250"
                                                        width="100%"
                                                        image="https://i.ytimg.com/vi/p3bck1lxCkU/hqdefault.jpg"
                                                        sx={{
                                                            margin: 0,
                                                            width: '100%',
                                                            "@media screen and (max-width: 375px) and (min-width: 0px)" :{
                                                                height: '142px'
                                                            },
                                                            "@media screen and (min-width: 376px)" : {
                                                                height: '162px'
                                                            },
                                                            "@media screen and (min-width : 600px)": {
                                                                height: '173px'
                                                            },
                                                            "@media screen and (min-width : 1440px)": {
                                                                height: '205px'
                                                            }
                                                        }}
                                                        alt="UKS"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div"
                                                            sx={{
                                                                fontSize: 15,
                                                                height: 40,
                                                                "@media screen and (max-width : 600px)": {
                                                                    overflow: 'hidden',
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: '2'
                                                                }
                                                            }}
                                                        >
                                                            Peluncuran Revitalisasi UKS melalui Sekolah Sehat - KEMENDIKBUD RI
                                                        </Typography>
                                                        <Divider />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}> <EventIcon />&nbsp;&nbsp;23 Agustus 2022</div>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <Card>
                                                <CardActionArea onClick={() => handleOpen({ embed_youtube: 'https://www.youtube.com/embed/Cbqg3RnW0ss' })}>
                                                    <CardMedia
                                                        component="img"
                                                        height="250"
                                                        width="100%"
                                                        image="https://i.ytimg.com/vi/Cbqg3RnW0ss/hqdefault.jpg"
                                                        sx={{
                                                            margin: 0,
                                                            width: '100%',
                                                            "@media screen and (max-width: 375px) and (min-width: 0px)" :{
                                                                height: '142px'
                                                            },
                                                            "@media screen and (min-width: 376px)" : {
                                                                height: '162px'
                                                            },
                                                            "@media screen and (min-width : 600px)": {
                                                                height: '173px'
                                                            },
                                                            "@media screen and (min-width : 1440px)": {
                                                                height: '205px'
                                                            }
                                                        }}
                                                        alt="UKS"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div"
                                                            sx={{
                                                                fontSize: 15,
                                                                height: 40,
                                                                "@media screen and (max-width : 600px)": {
                                                                    overflow: 'hidden',
                                                                    display: '-webkit-box',
                                                                    WebkitBoxOrient: 'vertical',
                                                                    WebkitLineClamp: '2'
                                                                }
                                                            }}
                                                        >
                                                            Sekolah Sehat - KEMENDIKBUD RI
                                                        </Typography>
                                                        <Divider />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}> <EventIcon />&nbsp;&nbsp;23 Agustus 2022</div>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid>

                                    <Dialog
                                        open={open}
                                        maxWidth="lg"
                                        fullWidth={true}
                                        onClose={() => setOpen(false)}
                                    >
                                        <DialogContent sx={{ padding: '0.4rem' }}>
                                            <iframe width={'100%'} height={500} src={embed}></iframe>
                                        </DialogContent>
                                    </Dialog>
                                </Box>
                            )}
                        />
                    </Grid>
                    {/* <Grid item sm={12} md={6}>
                        <Card
                            style={{
                                minHeight: '411px',
                                maxHeight: '411px',
                                marginBottom: '1.5rem'
                            }}
                        >
                            <MKBox p={3} mt={-1} textAlign={'left'}>
                                <MKBox mt={1} mb={3} style={{ height: '40px' }}>
                                    <MKTypography variant="h5" textTransform="capitalize" fontWeight="regular"
                                    style={{
                                        maxWidth: '100%',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: 17,
                                    }}>
                                        Program Sekolah Sehat
                                    </MKTypography>
                                </MKBox>
                                <MKBox mt={1} mb={3} style={{ height: '110px' }}>
                                    <MKTypography variant="body2" component="p" color="text"
                                        style={{
                                            maxWidth: '100%',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 5,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: 13
                                        }}
                                    >
                                        <List>
                                            <ListItem>Sehat Bergiz</ListItem>
                                            <ListItem>Sehat Fisik</ListItem>
                                            <ListItem>Sehat Imunisasi</ListItem>
                                        </List>
                                    </MKTypography>
                                </MKBox>
                                <MKButton
                                    component={MuiLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="gradient"
                                    size="small"
                                >
                                    Selengkapnya
                                </MKButton>
                            </MKBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </Container>
        </MKBox>
    );
}

export default SekolahSehat;
