/* eslint-disable */
// import PropTypes from "propty-pes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Breadcrumbs from "examples/Breadcrumbs";
// import routes from "routes";
import UKSFooter from "examples/Footers/UKSFooter";

function BaseLayout({ breadcrumb, title, children }) {
  return (
    <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
      <>
        <DefaultNavbar
          // routes={routes}
          routes={[]}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-react",
            label: "free download",
            color: "info",
          }}
          sticky
        />
      </>
      <Container sx={{ mt: 12 }}>
        <Grid container item xs={12} flexDirection="column" justifyContent="center" mx="auto">
          <MKBox width={{ xs: "100%", md: "100%", lg: "100%" }} mb={3}>
            <Breadcrumbs routes={breadcrumb} />
          </MKBox>
          <MKTypography variant="h4" mb={1} style={{ textTransform: 'capitalize' }}>
            {title || ''}
          </MKTypography>
          {children || ''}
        </Grid>
      </Container>
      <MKBox mt="auto">
        <UKSFooter />
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the BaseLayout
// BaseLayout.propTypes = {
//   breadcrumb: PropTypes.arrayOf(PropTypes.object).isRequired,
//   title: PropTypes.string.isRequired,
//   children: PropTypes.node.isRequired,
// };

export default BaseLayout;
