/* eslint-disable */
import { Chip, Grid, Typography, AppBar, Toolbar, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKButton from "components/MKButton";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { DownloadForOffline, Visibility } from "@mui/icons-material";

function BerkasKebijakan({ dataTable }) {
    const [open, setOpen] = useState(false);
    const [kebijakan, setKebijakan] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pdfv, setPdfv] = useState([]);
    const [dropdown, setDropdown] = useState(null);
    const [select, setSelect] = useState([]);
    const { page, kode_wilayah, files } = useParams();
    const thisPage = page || 1;

    useEffect(() => {
        setLoading(true);
        if (files) {
            axios({
                url: `/get_kebijakan/${kode_wilayah}/${thisPage}`,
                method: 'GET',
            }).then((e) => {
                setKebijakan(e.data.rows);
                setSelect(e.data.select.jenis_dokumen);
                setLoading(false);
            })
        } else {
            setKebijakan(dataTable);
            setLoading(false);
        }
    }, [dataTable]);


    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() + ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }


    const handleClickOpen = (opt) => {
        const file = opt.files[0];
        axios({
            url: `/cView/${file.file_id}`,
            method: "GET",
        });
        setOpen(true);
        setPdfv({
            title: opt.nama,
            file: `https://api-uks.kemdikbud.go.id/${file.path.replace('public/', 'storage/')}`,
            path: file.path
        });
    };

    const handleClose = () => {
        setOpen(false);
        setPdfv('');
    }

    const handleUnduh = async (path, nama, key1, fileId) => {
        axios({
            url: `/cUnduh/${fileId}`,
            method: "GET",
        });

        var config = {
            method: "get",
            url: `https://api-uks.kemdikbud.go.id/${path.replace('public/', 'storage/')}`,
            responseType: "blob",
        };

        let response = await axios(config);
        const a = document.createElement("a");
        a.style.display = "none";
        a.key = `akey${key1}`;
        document.body.appendChild(a);
        const blobFile = new Blob([response?.data], { type: response?.data?.type });
        const url = window.URL.createObjectURL(blobFile);
        a.href = url;
        a.download = nama;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "rotate(180deg)" : "rotate(0)",
        ...iconStyles,
    }

    return (
        <>
            <TableContainer>
                <Table size='small'>
                    <TableHead
                        sx={{
                            display: 'table-header-group',
                            borderRadius: '0',
                            padding: '0',
                            position: 'sticky',
                            top: '0',
                            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                            background: '#fff'
                        }}
                    >
                        <TableRow
                            sx={{
                                "& > th": {
                                    fontSize: '13px',
                                }
                            }}
                        >
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                Statistik
                            </TableCell>
                            <TableCell width="50%">
                                Nama Dokumen
                            </TableCell>
                            {/* <TableCell>
                                Jenis Dokumen
                            </TableCell> */}
                            <TableCell>
                                Tanggal Dokumen
                            </TableCell>
                            <TableCell>
                                Aksi
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {kebijakan?.length === 0 && !loading && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <Typography variant="body2" color="text.secondary">
                                        Data tidak ditemukan
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {kebijakan?.length !== 0 && kebijakan?.map((opt, key) => (
                            <TableRow
                                key={key}
                                hover
                                tabIndex={-1}
                            >
                                <TableCell>
                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px' }}>
                                        {key + 1}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {opt.files.map((file, key0) =>
                                        <div
                                            key={key0}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 700, fontSize: '14px',
                                                }}
                                            >
                                                <Visibility /> {file.c_view}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 700, fontSize: '14px',
                                                }}
                                            >
                                                <DownloadForOffline />{file.c_unduh}
                                            </Typography>
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px' }}>
                                        {opt.nama}
                                    </Typography>
                                </TableCell>
                                {/* <TableCell>
                                    <Chip component={Typography} sx={{ fontWeight: 700, fontSize: '14px' }} label={opt.jenis_dokumen} size="small" variant="outlined" />
                                </TableCell> */}
                                <TableCell>
                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px' }}>
                                        {handleTanggal(opt.tanggal_dokumen)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {
                                        opt.files.map((file, key1) => {
                                            return (
                                                <Box
                                                    key={key1}
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <MKButton color="info" size="small" onClick={e => handleClickOpen(opt)}>
                                                        <SearchIcon />&nbsp;Lihat
                                                    </MKButton>&nbsp;
                                                    <MKButton color="info" size="small" onClick={e => handleUnduh(file.path, file.nama, key1, file.file_id)}>
                                                        <DownloadIcon />&nbsp;Unduh
                                                    </MKButton>
                                                </Box>
                                            )
                                        })
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                fullScreen={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar style={{ minHeight: '40px' }}>
                            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                                {pdfv.title}
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent style={{ minWidth: '40vw', minHeight: '82vh' }}>
                    <iframe src={pdfv.file} style={{ height: '82vh', width: '100%' }} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default BerkasKebijakan;
