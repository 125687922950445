/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from "react";
import DokumenCard from "examples/Cards/BlogCards/DokumenCard";
import { Card, CardActionArea, CardContent, CardMedia, Dialog, DialogContent, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import EventIcon from '@mui/icons-material/Event';
import AppsIcon from '@mui/icons-material/Apps';

function Video({ video }) {
    const [rows, setRows] = useState(video);
    const [open, setOpen] = useState(false);
    const [embed, setEmbed] = useState('');
    const smallDevice = useMediaQuery('(max-width:500px)');
    const mobileDeviceDown = useMediaQuery('(max-width:600px)');
    const mobileDeviceUp = useMediaQuery('(min-width:600px)');
    const desktop = useMediaQuery('(min-width:1440px)');

    useEffect(() => {
        setRows(video);
    }, [video])

    const heightCardMedia = () => {
        if (mobileDeviceDown) {
            return "91";
        } else if (desktop) {
            return "160";
        } else if(smallDevice){
            return "80";
        }else{
            return "135";
        }
    }

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() + ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    const handleOpen = (opt) => {
        setOpen(true);
        setEmbed(opt.embed_youtube);
    }

    return (
        <MKBox component="section" py={2}>
            <Container sx={{ pl: '0px', pr: '0px' }}>
                <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
                    <MKTypography variant="h3" mb={6}>
                        Video
                    </MKTypography>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                    {rows.map((opt, key) => {
                        return (
                            <Grid key={key} item xs={12} md={6} lg={3}>
                                <Card>
                                    <CardActionArea onClick={() => handleOpen(opt)}>
                                        <CardMedia
                                            component="img"
                                            height={heightCardMedia()}
                                            width="100%"
                                            image={opt.thumbnail}
                                            style={{
                                                margin: 0,
                                                width: '100%'
                                            }}
                                            alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom
                                                variant="h5"
                                                component="div"
                                                style={{
                                                    maxWidth: '100%',
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 2,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    fontSize: 15,
                                                    minHeight: 35
                                                }}
                                            >
                                                {opt.nama}
                                            </Typography>
                                            <Divider />
                                            <Typography variant="caption" color="text.secondary">
                                                <div style={{ display: 'flex', alignItems: 'center' }}> <EventIcon />&nbsp;{handleTanggal(opt.create_date)}</div>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid container style={{ marginTop: '1.4rem' }}>
                    <MKButton
                        component={MuiLink}
                        href={'/dokumen/publikasi/video'}
                        rel="noreferrer"
                        variant="gradient"
                        size="medium"
                        color="success"
                        fullWidth={true}
                        sx={{ fontSize: "14px" }}
                    >
                        <AppsIcon />&nbsp;
                        Semua Video
                    </MKButton>
                </Grid>
            </Container>
            <Dialog
                open={open}
                maxWidth="lg"
                fullWidth={true}
                onClose={() => setOpen(false)}
            >
                <DialogContent sx={{ padding: '0.4rem' }}>
                    <iframe width={'100%'} height={500} src={embed}></iframe>
                </DialogContent>
            </Dialog>
        </MKBox>
    );
}

export default Video;
