/* eslint-disable */
import { styled, alpha } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Chip, Divider, Grid, InputBase, Link, Skeleton, Tab, Tabs } from "@mui/material";
import BeritaCard from "examples/Cards/BlogCards/BeritaCard";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import defaultNewsImg from 'assets/images/defaultNews.jpg';
import { useNavigate, useParams } from "react-router-dom";
import { Pagination, Stack } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import SearchIcon from '@mui/icons-material/Search';
import Calender from 'pages/UKS/Widgets/Calender/Calender';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  }));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    fontSize: '0.8rem',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        // width: '20ch',
        },
    },
  }));

function Berita() {
    const [berita, setBerita] = useState([]);
    const [countPage, setCountPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('list'); // grid,list
    const [search, setSearch] = useState('');
    const [kategori, setKategori] = useState([]);
    const [selected, setSelected] = useState([]);

    const { page } = useParams();
    const navigate = useNavigate();
    const limitPage = 9;
    const thisPage = parseInt(page) || 1;

    const tabs = [
        {
            value: 'grid',
            icon: <GridViewIcon/>,
            label: "Grid"
        },
        {
            value: 'list',
            icon: <ListAltIcon/>,
            label: "List"
        }
    ]

    useEffect(() => {
        getData(thisPage);
    }, [page]);
    
    const getData = (thisPage_) => {
        setLoading(true);
        axios({
            url: `/get_berita/${thisPage_}?limit=${limitPage}&search=${search}&kategori=${selected}`,
            method: 'GET',
        }).then((e) => {            
            const { rows, count_all, select } = e.data;
            const data = Math.ceil(count_all/limitPage);
            setBerita(rows);
            setCountPage(data);
            setLoading(false);
            setKategori(select.kategori_berita);

            if(selected.length === 0){
                setAll(select.kategori_berita);
            }
        })
    }

    const setAll = (data) => {
        const dataSelectAll = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            dataSelectAll.push(element.value);
        }

        setSelected(dataSelectAll);
    }

    const handleChange = (event, value) => {
        navigate(`/halaman/berita/${value}`)
    }

    const handleTabType = (event, newValue) => {
        setActiveTab(newValue);
    }

    const handleDate = (value) => {
        if(value){
            const time = new Date(value);
            const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

            return `${time.getDate()} ${bulan[parseInt(time.getMonth())]} ${time.getFullYear()}`;
        }
    }

    const handleClick = (value) => {
        if(value === 'semua'){
            setAll(kategori);
            getData(1);
        }else{
            let data = selected;

            if(data.includes(value)){
                // console.log('remove');
                const index = data.indexOf(value);
                if (index > -1) { data.splice(index, 1); }

                setSelected(obj => [...data]);
                getData(1);
            }else{
                // console.log('add');
                data.push(value);
                setSelected(obj => [...data]);
                getData(1);
            }
        }
    }

    const handleSearch = () => {
        getData(1);
        navigate(`/halaman/berita`);
    }

    return (
        <BaseLayout
            title="Berita"
            breadcrumb={[
                { label: "Berita" },
            ]}
            >
            <Grid container spacing={2} style={{marginBottom: '2rem' }}>
                <Grid item md={9}>
                    <Card>
                        <CardHeader/>
                        <CardContent>
                            {
                                loading && (
                                    <Grid container spacing={3}>
                                        {[1,2,3,4,5,6,7,8,9].map((opt, key) => <Grid key={key} item xs={12} sm={12} ><Skeleton variant="rectangular" width={"100%"} height={120} /></Grid>)}
                                    </Grid>
                                )
                            }
                            {
                                !loading && activeTab === 'grid' && <Grid item container spacing={3} xs={12} sm={12}>
                                    {
                                        berita.map((opt, key) =>
                                            <Grid item key={key} xs={12} sm={6} lg={4}>
                                                <BeritaCard
                                                    align="left"
                                                    image={opt.images ? opt.images : defaultNewsImg}
                                                    title={opt.title}
                                                    description={opt.description}
                                                    description_view={'none'}
                                                    height={'270px'}
                                                    action={{
                                                        type: "internal",
                                                        route: `/halaman/berita-detail/${opt.slug}`,
                                                        color: "success",
                                                        label: "lebih lanjut",
                                                    }}
                                                />
                                            </Grid>   
                                        )
                                    }
                                </Grid>
                            }
                            {
                                !loading && activeTab === 'list' && (
                                    <Grid item container spacing={1} xs={12} sm={12}>
                                        <Grid item xs={12} sm={12}>
                                                {
                                                    berita.map((opt, key) => {
                                                        return (
                                                            <div key={key}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={5} md={2}>
                                                                        <MKBox
                                                                            style={{
                                                                                backgroundImage: `url(${opt.images ? opt.images : defaultNewsImg})`,
                                                                                backgroundSize: 'cover'
                                                                            }}
                                                                            alt={opt.title}
                                                                            width={'100%'}
                                                                            height={110}
                                                                            borderRadius="md"
                                                                            shadow="lg"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={7} md={10}>
                                                                        <Link href={`/halaman/berita-detail/${opt.slug}`}><MKTypography variant="h6" >{ opt.judul }</MKTypography></Link>
                                                                        <MKTypography variant="caption">Jenis : <b>{opt.jenis_berita}</b>&nbsp;|&nbsp;Kategori : <b>{opt.kategori_berita}</b>&nbsp;|&nbsp;Tanggal : <b>{handleDate(opt.tanggal_publis)}</b></MKTypography>
                                                                        <div style={{ fontSize: 12 }} dangerouslySetInnerHTML={{__html: opt.description + "..." }} />
                                                                        <Link href={`/halaman/berita-detail/${opt.slug}`}><b style={{ fontSize: 12 }}>(Baca Selengkapnya)</b></Link>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider/>
                                                            </div>
                                                        )
                                                    })
                                                }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            <Grid container item justifyContent="center" xs={12} lg={6} mx="auto" height="100%">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={countPage}
                                        defaultPage={thisPage}
                                        color="secondary"
                                        showFirstButton
                                        showLastButton
                                        onChange={handleChange}
                                    />
                                </Stack>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={3}>
                    <Card style={{ marginBottom: '0.8rem' }}>
                        <CardContent style={{ padding: 8, backgroundColor: '#f8f9fa', borderRadius: 7 }}>
                            <Search className='items-center'>
                                <SearchIconWrapper>
                                    <SearchIcon fontSize='0.8rem'/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Pencarian..."
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyPress={e => {
                                        if(e.key === 'Enter'){
                                            handleSearch()
                                        }
                                    }}
                                />
                            </Search>
                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: '0.8rem' }}>
                        <Tabs value={activeTab} onChange={handleTabType} aria-label="scrollable auto tabs example">
                            {tabs.map((opt, keytab) => <Tab key={keytab} value={opt.value} icon={opt.icon} iconPosition="start" label={opt.label} />)}                
                        </Tabs>
                    </Card>
                    <Card style={{ marginBottom: '0.8rem' }}>
                        <CardHeader title="Kategori" style={{ padding: 8 }}/>
                        <CardContent style={{ padding: 8 }}>
                            <Chip
                                variant={ selected.length === kategori.length ? "filled" : "outlined"}
                                color="success"
                                size="small"
                                label="Semua"
                                onClick={e => handleClick('semua')}
                                style={{
                                    marginRight: 3,
                                    color: (selected.length === kategori.length ? "#fff" : "#4caf50")
                                }}
                            />
                            {
                                kategori.map((opt, key) => {
                                    const sel = selected.includes(opt.value);
                                    
                                    return (
                                        <Chip
                                            key={key}
                                            variant={ sel ? "filled" : "outlined" }
                                            color="success"
                                            size="small"
                                            label={opt.label}
                                            style={{
                                                marginRight: 3,
                                                color: (sel ? '#fff' : '#4caf50')
                                            }}
                                            onClick={e => handleClick(opt.value)}
                                        />
                                    )
                                })
                            }
                        </CardContent>
                    </Card>
                    <Calender/>
                </Grid>
            </Grid>
            {/* <Grid container spacing={1}>
                <Grid item xs={0} lg={10}>&nbsp;</Grid>
                <Grid item xs={12} lg={2}>
                    
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '30px', marginBottom: '30px' }}>
                
            </Grid>
            <Grid container item justifyContent="center" xs={12} lg={6} mx="auto" height="100%" mt={2} mb={2} sx={{ mb: 10 }}>
                
            </Grid> */}
        </BaseLayout>
    )
}

export default Berita;