/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import defaultNewsImg from "assets/images/default-image.png";
import BeritaCard from "examples/Cards/BlogCards/BeritaCard";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AppsIcon from "@mui/icons-material/Apps";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";

function Places({ berita, praktekbaik }) {
  const [tabs, setTabs] = useState("berita");
  const [rows, setRows] = useState(berita);

  useEffect(() => {
    setRows(berita);
    setTabs("berita");
  }, [berita]);

  const handleChangeTab = (tab) => {
    setTabs(tab);
    switch (tab) {
      case "berita":
        setRows(berita);
        break;
      case "praktekbaik":
        setRows(praktekbaik);
        break;
      default:
        setRows(berita);
        break;
    }
  };

  return (
    <>
      <MKBox component="section" py={2}>
        <Container sx={{ pl: "0px", pr: "0px" }}>
          <Grid container item xs={12} lg={6} mt={3} justifyContent="center" mx="auto">
            <MKTypography variant="h3" mb={6}>
              Berita Terupdate
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            {berita.length === 0 && (
              <>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
              </>
            )}
            {berita.map((item, key) => (
              <Grid item xs={12} sm={6} lg={3} key={key}>
                <BeritaCard
                  align="left"
                  image={item.images ? item.images : defaultNewsImg}
                  title={item.title}
                  description={item.description}
                  description_view={""}
                  height={"411px"}
                  action={{
                    type: "internal",
                    route: `/halaman/berita-detail/${item.slug}`,
                    color: "success",
                    label: "Baca Selengkapnya",
                    size: "small",
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container>
            <MKButton
              component={MuiLink}
              href={"/halaman/berita"}
              rel="noreferrer"
              variant="gradient"
              size="medium"
              color="success"
              fullWidth={true}
              sx={{ fontSize: "14px" }}
            >
              <AppsIcon />
              &nbsp; Semua Berita
            </MKButton>
          </Grid>
        </Container>
      </MKBox>

      <MKBox component="section" py={2}>
        <Container sx={{ pl: "0px", pr: "0px" }}>
          <Grid container item xs={12} lg={6} mt={3} justifyContent="center" mx="auto">
            <MKTypography variant="h3" mb={6}>
              Praktik Baik Terupdate
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            {praktekbaik.length === 0 && (
              <>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={400}
                    style={{ marginBottom: 11 }}
                  />
                </Grid>
              </>
            )}

            {praktekbaik.map((item, key) => (
              <Grid item xs={12} sm={6} lg={3} key={key}>
                <BeritaCard
                  align="left"
                  image={item.images ? item.images : defaultNewsImg}
                  title={item.judul}
                  description={item.description}
                  action={{
                    type: "internal",
                    route: `/informasi/praktik-baik-detail/${item.slug}`,
                    color: "success",
                    label: "Baca Selengkapnya",
                    size: "small",
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container>
            <MKButton
              component={MuiLink}
              href={"/halaman/berita"}
              rel="noreferrer"
              variant="gradient"
              size="medium"
              color="success"
              fullWidth={true}
              sx={{ fontSize: "14px" }}
            >
              <AppsIcon />
              &nbsp; Semua Praktik Baik
            </MKButton>
          </Grid>
        </Container>
      </MKBox>

      {/* OLDDD Dipisahkan */}
      {/* <MKBox component="section" py={2}>
        <Container sx={{ pl: '0px', pr: '0px' }}>
          <Grid container spacing={1}>
            <Grid item sm={1.7} xs={5.5}>
              <MKButton disabled={tabs === 'berita'} onClick={() => handleChangeTab('berita')} fullWidth={true} color="success" size="small"><ChevronLeftIcon/>&nbsp;Berita Terkini</MKButton>
            </Grid>
            <Grid item sm={8.6} xs={1}>&nbsp;</Grid>
            <Grid item sm={1.7} xs={5.5}>
              <MKButton disabled={tabs === 'praktekbaik'} onClick={() => handleChangeTab('praktekbaik')} fullWidth={true} color="success" size="small">Praktek Baik&nbsp;<ChevronRightIcon/></MKButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={6} mt={3} justifyContent="center" mx="auto">
            <MKTypography variant="h3" mb={6}>
              {
                tabs === 'berita' ? 'Berita Terupdate' :
                tabs === 'praktekbaik' ? 'Praktek Baik Terupdate' :
                ''
              }
            </MKTypography>
          </Grid>
          <Grid container spacing={3}>
            {
              rows.length === 0 && (
                <>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" width={"100%"} height={400} style={{ marginBottom: 11 }} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" width={"100%"} height={400} style={{ marginBottom: 11 }} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" width={"100%"} height={400} style={{ marginBottom: 11 }} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Skeleton variant="rectangular" width={"100%"} height={400} style={{ marginBottom: 11 }} />
                  </Grid>
                </>
              )
            }
            {
              tabs === 'berita' && rows.map((item, key) =>
                <Grid item xs={12} sm={6} lg={3} key={key}>
                  <BeritaCard
                    align="left"
                    image={item.images ? item.images : defaultNewsImg}
                    title={item.title}
                    description={item.description}
                    description_view={''}
                    height={'411px'}
                    action={{
                      type: "internal",
                      route: `/halaman/berita-detail/${item.slug}`,
                      color: "success",
                      label: "Baca Selengkapnya",
                      size: "small"
                    }}
                  />
                </Grid>
              )
            }
            
            {
              tabs === 'praktekbaik' && rows.map((item, key) =>
                <Grid item xs={12} sm={6} lg={3} key={key}>
                  <BeritaCard
                    align="left"
                    image={item.images ? item.images : defaultNewsImg}
                    title={item.judul}
                    description={item.description}
                    action={{
                      type: "internal",
                      route: `/informasi/praktik-baik-detail/${item.slug}`,
                      color: "success",
                      label: "Baca Selengkapnya",
                      size: "small"
                    }}
                  />
                </Grid>
              )
            }
          </Grid>
          <Grid container>
            <MKButton
                component={MuiLink}
                href={'/halaman/berita'}            
                rel="noreferrer"
                variant="gradient"
                size="medium"
                color="success"
                fullWidth={true}
                sx={{fontSize:"14px"}}
              >
                <AppsIcon/>&nbsp;
                Semua Berita
              </MKButton>
          </Grid>
        </Container>
      </MKBox> */}
    </>
  );
}

export default Places;
