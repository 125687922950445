/* eslint-disable */
import { Grid, Typography, Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, CircularProgress } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import BoxData from "./components/BoxData";
import TableData from "./components/TableData";
import generateTableForExcel from "./components/generateTableForExcel";
import exportTableToExcel from "pages/UKS/Widgets/ExportTable/exportTableToExcelService";
import { ArrowRightAlt, CheckCircle, FileDownload } from "@mui/icons-material";
import BerkasKebijakan from "./components/BerkasKebijakan";
import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const columns = [
    {
        id: 'nama',
        label: 'Nama',
        width: '40%'
    },
    {
        id: 'count',
        label: 'Jumlah Berkas',
    },
];

const columns2 = [
    {
        id: 'nama',
        label: 'Nama',
        width: '40%'
    },
    {
        id: 'count',
        label: 'Status',
        type: 'icon',
        align: 'center'
    },
    {
        id: 'count',
        label: 'Jumlah Berkas',
        width: '40%'
    },
];

function ProdukHukumNew() {
    const navigate = useNavigate();
    const [pageTable, setPageTable] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [wilayah, setWilayah] = useState();
    const [getWidget, setGetWidget] = useState([]);
    const { page, kode_wilayah, files } = useParams();
    const thisPage = page || 1;

    useEffect(() => {
        setLoading(true);
        if (kode_wilayah) {
            axios({
                url: `/get_kebijakan_wilayah/${kode_wilayah}  `,
                method: "GET"
            }).then((e) => {
                if (e.data) {
                    setLoading(false);
                    setRows(e.data.rows);
                    setGetWidget(e.data.widgets);
                } else {
                    setLoading(false);
                    setRows([]);
                    setGetWidget([]);
                }
            })
        } else {
            axios({
                url: `/get_kebijakan_wilayah/000000`,
                method: "GET"
            }).then((e) => {
                if (e.data) {
                    setLoading(false);
                    setRows(e.data.rows);
                    setGetWidget(e.data.widgets);
                } else {
                    setLoading(false);
                    setRows([]);
                    setGetWidget([]);
                }
            })
        }
    }, [kode_wilayah]);

    const downloadExcel = () => {
        const labelExcel = 'Tabel Pemda Yang Sudah Mengeluarkan Kebijakan (Surat Edaran) Sekolah Sehat / UKS';
        const tableForExcel = generateTableForExcel(
            'tablePemdaSudahMengeluarkanKebijakan',
            labelExcel,
        );

        exportTableToExcel(tableForExcel, labelExcel);
    };

    const handleChangePage = (event, newPage) => {
        setPageTable(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPageTable(0);
    };

    const handleClickRow = (row) => {
        if (!kode_wilayah) {
            navigate(`/dokumen/produk-hukum-new/${row.kode_wilayah.trim()}`);
            setWilayah(
                prev => ({
                    ...prev,
                    nama_prov: row.nama,
                    kode_wilayah_prov: row.kode_wilayah,
                })
            );
        } else {
            navigate(`/dokumen/produk-hukum-new/${row.kode_wilayah.trim()}/files`);
            setWilayah(
                prev => ({
                    ...prev,
                    nama_kota: row.nama,
                    kode_wilayah_kota: row.kode_wilayah,
                })
            );
        }
    };

    return (
        <BaseLayout
            breadcrumb={[
                { label: 'Kebijakan (Surat Edaran)', route: '/dokumen/produk-hukum-new' },
                { label: `${kode_wilayah && wilayah?.nama_prov || ''}`, route: `/dokumen/produk-hukum-new/${wilayah?.kode_wilayah_prov}` },
                { label: `${files && wilayah?.nama_kota || ''}` },
                { label: `${files && 'Daftar Dokumen' || ''}` },
            ]}
        >
            <Grid container spacing={2} style={{ marginBottom: '30px' }}>
                <Grid item xs={12}>
                    <center>
                    <MKTypography variant="h4" mb={0.2} style={{ textTransform: 'capitalize' }}>
                        Surat Edaran Tahun 2024 tentang Gerakan Sekolah Sehat
                    </MKTypography>
                    </center>
                </Grid>
                <Grid item xs={12} md={12}>
                    <center>
                    <MKButton color="success" onClick={() => { window.open('https://api-uks.kemdikbud.go.id/storage/manajemen_uks/files/produk_hukum/nvkEsKVa4mKyJr44sEeWVQM2LLtez1SX82SdI4zc.pdf', '_blank') }}>
                        <MKTypography variant="h4" mb={0.2} color="white" style={{ textTransform: 'capitalize' }}><FileDownload style={{ fontSize: '24px' }}/>&nbsp;Unduh Surat Edaran GSS 2024</MKTypography>
                    </MKButton>
                    </center>
                </Grid>
                <Grid item xs={12}>
                    <center>
                    <MKTypography variant="h4" mb={0.2} style={{ textTransform: 'capitalize' }}>
                        Surat Edaran Tahun 2023 tentang Kampanye Sekolah Sehat
                    </MKTypography>
                    </center>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginBottom: '30px' }}>
                    <center>
                    <MKButton style={{ width: '100%' }} color="success" onClick={() => { window.open('https://drive.google.com/file/d/1xyJXy6hQ8oiRaXFeZ4bC-r3YYFRnUIrB/view', '_blank') }}>
                        <MKTypography variant="h4" mb={0.2} color="white" style={{ textTransform: 'capitalize' }}><FileDownload style={{ fontSize: '24px' }}/>&nbsp;Unduh Surat Edaran Pusat</MKTypography>
                    </MKButton>&nbsp;
                    <MKButton style={{ width: '100%' }} color="info" onClick={() => { window.open('https://docs.google.com/spreadsheets/d/1YJ1Y89USTCKwSLhq41hP5VccqYR_iD3aU03aFDGqC6U/edit?usp=sharing', '_blank') }}>
                        <MKTypography variant="h4" mb={0.2} color="white" style={{ textTransform: 'capitalize' }}><FileDownload style={{ fontSize: '24px' }}/>&nbsp;Daftar Surat Edaran  Pemda</MKTypography>
                    </MKButton>
                    </center>
                </Grid>
                <Grid item xs={12}>
                    <MKTypography variant="h4" mb={0.2} style={{ textTransform: 'capitalize' }}>
                        Ringkasan Data SE Pemda Provinsi
                    </MKTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <BoxData
                        backgroundColor="#dfb557"
                        title="Jumlah Pemprov"
                        totalData={!loading ? getWidget?.jumlah_pemprov : '0'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BoxData
                        color="#fff"
                        backgroundColor="#c5352a"
                        title={<>Pemprov Belum <br />Mengeluarkan SE</>} totalData={!loading ? getWidget?.jumlah_pemprov_belum : '0'}
                        totalPersenData={!loading ? ((getWidget?.jumlah_pemprov_belum / getWidget?.jumlah_pemprov * 100).toFixed(2) + '%') : '0%'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BoxData
                        color="#fff"
                        backgroundColor="#3b893f"
                        title={<>Pemprov Sudah <br />Mengeluarkan SE</>} totalData={!loading ? getWidget?.jumlah_pemprov_sudah : '0'}
                        totalPersenData={!loading ? ((getWidget?.jumlah_pemprov_sudah / getWidget?.jumlah_pemprov * 100).toFixed(2) + '%') : '0%'}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MKTypography variant="h4" mt={0.8} mb={0.2} style={{ textTransform: 'capitalize' }}>
                        Ringkasan Data SE Pemda Kabupaten/Kota
                    </MKTypography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <BoxData
                        backgroundColor="#e9c16a"
                        title="Jumlah Pemda"
                        totalData={!loading ? getWidget?.jumlah_pemda : '0'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BoxData
                        color="#fff"
                        backgroundColor="#F44335"
                        title={<>Pemda Belum <br />Mengeluarkan SE</>} totalData={!loading ? getWidget?.jumlah_pemda_belum : '0'}
                        totalPersenData={!loading ? ((getWidget?.jumlah_pemda_belum / getWidget?.jumlah_pemda * 100).toFixed(2) + '%') : '0%'}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BoxData
                        color="#fff"
                        backgroundColor="#4CAF50"
                        title={<>Pemda Sudah <br />Mengeluarkan SE</>} totalData={!loading ? getWidget?.jumlah_pemda_sudah : '0'}
                        totalPersenData={!loading ? ((getWidget?.jumlah_pemda_sudah / getWidget?.jumlah_pemda * 100).toFixed(2) + '%') : '0%'}
                    />
                </Grid>
                <Grid item xs={12}>
                    {files ? (
                        <>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    flexDirection: "row",
                                    "@media (max-width: 600px)": {
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }
                                }}
                            >
                                <MKTypography variant="h6" fontSize="16px" marginTop="10px" style={{ textTransform: 'capitalize' }}>
                                    Daftar Dokumen/File
                                </MKTypography>
                            </Box>
                            <BerkasKebijakan />
                        </>
                    ) : (
                        <>
                            {kode_wilayah && (
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{
                                            flexDirection: "row",
                                            "@media (max-width: 600px)": {
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                            }
                                        }}
                                    >
                                        <MKTypography variant="h6" fontSize="16px" marginTop="10px" style={{ textTransform: 'capitalize' }}>
                                            Tabel Surat Edaran {kode_wilayah && wilayah?.nama_prov}
                                        </MKTypography>
                                    </Box>
                                    <BerkasKebijakan dataTable={getWidget?.kebijakan} />
                                </>
                            )}
                            <Box
                                marginTop="30px"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    flexDirection: "row",
                                    "@media (max-width: 600px)": {
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }
                                }}
                            >
                                <MKTypography variant="h6" fontSize="16px" marginTop="10px" style={{ textTransform: 'capitalize' }}>
                                    Tabel Pemda yang Sudah Mengeluarkan Kebijakan (Surat Edaran) Sekolah Sehat
                                </MKTypography>
                                <MKButton
                                    size="small"
                                    onClick={downloadExcel}
                                    variant="contained"
                                    color="success"
                                >
                                    <FileDownload />&nbsp; Unduh Excel
                                </MKButton>
                            </Box>
                            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: "10px", padding: '0.8rem' }}>
                                <TableContainer>
                                    <Table id="tablePemdaSudahMengeluarkanKebijakan" size='small'>
                                        <TableHead
                                            sx={{
                                                display: 'table-header-group',
                                                borderRadius: '0',
                                                padding: '0',
                                                position: 'sticky',
                                                top: '0',
                                                boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                                                background: '#fff'
                                            }}
                                        >
                                            <TableRow
                                                sx={{
                                                    "& > th": {
                                                        fontSize: '13px',
                                                    }
                                                }}
                                            >
                                                <TableCell width="5%">
                                                    No
                                                </TableCell>
                                                {!kode_wilayah && columns.map((column, key) => (
                                                    <TableCell
                                                        key={key}
                                                        align={column.align}
                                                        width={column.width}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                                {kode_wilayah && columns2.map((column, key) => (
                                                    <TableCell
                                                        key={key}
                                                        align={column.align}
                                                        width={column.width}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loading && (
                                                <TableRow>
                                                    <TableCell colSpan={kode_wilayah ? 5 : 4} align="center">
                                                        <MKTypography variant="h6" fontSize="16px" marginTop="10px" style={{ textTransform: 'capitalize' }}>
                                                            <CircularProgress
                                                                size={15}
                                                                sx={{
                                                                    height: 'auto',
                                                                    marginRight: '8px',
                                                                    color: '#344767'
                                                                }}
                                                            /> Loading
                                                        </MKTypography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {!loading && rows
                                                .slice(pageTable * rowsPerPage, pageTable * rowsPerPage + rowsPerPage)
                                                .map((row, key) => (
                                                    <TableRow
                                                        key={key}
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        sx={{
                                                            position: 'relative',
                                                            cursor: `${row.count > 0 ? 'pointer' : 'default'}`,
                                                            "&:hover": {
                                                                "& > td": {
                                                                    color: `${row.count > 0 && "#4CAF50"}`
                                                                },
                                                            },
                                                            "& > td:is(:last-child)": {
                                                                "& > p.action": {
                                                                    display: `${row.count > 0 ? 'flex' : 'none'}`,
                                                                    fontWeight: '500',
                                                                    alignItems: 'center',
                                                                    gap: '10px',
                                                                    position: 'absolute',
                                                                    right: '50px',
                                                                    top: '10px',
                                                                    "@media (max-width: 600px)": {
                                                                        position: 'unset',
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (row.count > 0) {
                                                                handleClickRow(row);
                                                            }
                                                            return false;
                                                        }}
                                                    >
                                                        <TableCell>
                                                            <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px' }}>
                                                                {pageTable === 0 ? key + 1 : pageTable * rowsPerPage + key + 1}
                                                            </Typography>
                                                        </TableCell>
                                                        {!kode_wilayah && columns.map((column, key1) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={key1} align={column.align}>
                                                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px' }}>
                                                                        {column.format && typeof value === 'number'
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </Typography>
                                                                    {key1 === 1 && (
                                                                        <Typography className="action" sx={{ display: "none", fontWeight: 700, fontSize: '14px' }} variant="body2">
                                                                            Lihat Berkas <ArrowRightAlt />
                                                                        </Typography>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                        {kode_wilayah && columns2.map((column, key1) => {
                                                            const value = row[column.id];
                                                            const type = column.type ? column.type : "";
                                                            return (
                                                                <TableCell key={key1} align={column.align}>
                                                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px' }}>
                                                                        {type === "icon" && (parseInt(value) > 0 ? <CheckCircle fontSize="small" sx={{ color: "#4CAF50" }} /> : "")}
                                                                        {!type && (column.format && typeof value === 'number' ? column.format(value) : value)}
                                                                    </Typography>
                                                                    {key1 === 2 && (
                                                                        <Typography className="action" sx={{ display: "none", fontWeight: 700, fontSize: '14px' }} variant="body2">
                                                                            Lihat Berkas <ArrowRightAlt />
                                                                        </Typography>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    style={{ marginTop: '0.4rem' }}
                                    rowsPerPageOptions={[10, 25, 50, { label: "All", value: rows.length + 1 }]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={pageTable}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </>
                    )}
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default ProdukHukumNew;
