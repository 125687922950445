/* eslint-disable */
import { Chip, Divider, Grid, InputAdornment, List, ListItem, ListItemText, Menu, MenuItem, Typography, Icon, AppBar, Toolbar, IconButton } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKButton from "components/MKButton";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import MKInput from "components/MKInput"; 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';

function Kebijakan() {
    const [kebijakan, setKebijakan] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [pdfv, setPdfv] = useState([]);
    const [dropdown, setDropdown] = useState(null);
    const [select, setSelect] = useState([]);
    const [selected, setSelected] = useState('');
    const { page } = useParams();
    const thisPage = page || 1;

    useEffect(() => {
        setLoading(true);
        axios({
            url: `/get_kebijakan/${thisPage}`,
            method: 'GET',
        }).then((e) => {
            setKebijakan(e.data.rows);
            setSelect(e.data.select.jenis_dokumen);
            setLoading(false);
        })
    }, []);

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = (opt) => {
        const file = opt.files[0];
        axios({
            url: `/cView/${file.file_id}`,
            method: "GET",
        });
        setOpen(true);
        const urlPath = file.path.includes('http') ? file.path : `https://is3.cloudhost.id/storagedirectus1/${file.path}`
        setPdfv({
            title: opt.nama,
            file: urlPath,
            // file: `https://is3.cloudhost.id/storagedirectus1/${file.path}`,
            path: file.path
        });
    };

    const handleClose = () => {
        setOpen(false);
        setPdfv('');
    }

    const handleUnduh = async (path,nama,key1,fileId) => {
        axios({
          url: `/cUnduh/${fileId}`,
          method: "GET",
        });
        
        // IF path is url directly
        const urlPath = path.includes('http') ? path : `https://is3.cloudhost.id/storagedirectus1/${path}`

        var config = {
          method: "get",
          url: urlPath,
        //   url: `https://is3.cloudhost.id/storagedirectus1/${path}`,
          responseType: "blob",
        };

        let response = await axios(config);
        const a = document.createElement("a");
        a.style.display = "none";
        a.key = `akey${key1}`;
        document.body.appendChild(a);
        const blobFile = new Blob([response?.data], { type: response?.data?.type });
        const url = window.URL.createObjectURL(blobFile);
        a.href = url;
        a.download = nama;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "rotate(180deg)" : "rotate(0)",
        ...iconStyles,
    }

    return (
        <BaseLayout
            title="Kebijakan"
            breadcrumb={[
                { label: "Kebijakan" },
            ]}
        >
            <Grid container spacing={3} style={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={9}>
                            <MKInput
                                variant="standard"
                                placeholder="Pencarian"
                                name='search'
                                value={search}
                                size="small"
                                onChange={e => {
                                    setSearch(e.target.value)
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <MKButton variant="gradient" color="warning" onClick={openDropdown} fullWidth={true} size="small">
                            { selected || 'Semua'} <Icon sx={dropdownIconStyles}>expand_more</Icon>
                            </MKButton>
                            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
                                <MenuItem onClick={e => { setSelected(''); closeDropdown()}}>Semua</MenuItem>
                                {
                                    select.map((opt, key) =>
                                        <MenuItem key={key} value={opt.value} onClick={e => { setSelected(opt.label); closeDropdown()}}>{ opt.label }</MenuItem>   
                                    )
                                }
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    { loading && "Memuat..." }
                    {
                        !loading && 
                        kebijakan
                        .filter((e)=>{
                            return (e.nama && e.nama.toUpperCase().includes(search.toUpperCase()) && e.jenis_dokumen.toUpperCase().includes(selected.toUpperCase()) );
                        })
                        .map((opt, key) =>
                            <span key={key}>
                                <ListItem>
                                    <Grid container spacing={1}>
                                        <Grid item sm={9} xs={12}>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 13 }}
                                                            component="span"
                                                            variant="body2"
                                                            fontWeight={'bold'}
                                                        >
                                                            {`${(key+1)}. ${opt.nama}`}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                <React.Fragment>
                                                    <div style={{ fontSize: 12 }}>
                                                        <Chip label={ opt.jenis_dokumen } size="small" variant="outlined" />
                                                        {`  `}
                                                        {`${handleTanggal(opt.tanggal_dokumen)} | `}                                                    
                                                        <Typography
                                                            sx={{ display: 'inline', fontSize: 12 }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            { opt.client }
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item container spacing={1} sm={3} xs={12}>
                                            {
                                                opt.files.map((file, key1) =>{
                                                return (
                                                    <Grid item xs={12} key={key1}>
                                                        <MKButton color="info" size="small" onClick={e => handleClickOpen(opt)}>
                                                            <SearchIcon />&nbsp;Lihat 
                                                        </MKButton>&nbsp;
                                                        <MKButton color="info" size="small" onClick={e => handleUnduh(file.path,file.nama,key1,file.file_id)}>
                                                            <DownloadIcon />&nbsp;Unduh
                                                        </MKButton>
                                                    </Grid>
                                                )})
                                            }
                                            <Grid item xs={12}>
                                                { opt.files.map((file, key0) =>
                                                    <Typography variant="caption" key={key0}>
                                                        {`${file.c_view} dilihat | ${file.c_unduh} diunduh `}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider variant="inset" component="li" style={{ margin: '8px 1px 6px 1px' }}/>
                            </span>
                        )
                    }
                    </List>
                </Grid>
            </Grid>
            
            <Dialog
                fullScreen={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar style={{ minHeight: '40px' }}>
                            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
                                { pdfv.title }
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent style={{ minWidth: '40vw', minHeight: '82vh' }}>
                    <iframe src={pdfv.file} style={{ height: '82vh', width: '100%' }}/>
                </DialogContent>
            </Dialog>
        </BaseLayout>
    )
}

export default Kebijakan;
