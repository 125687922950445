/* eslint-disable */
import { Card, CardActionArea, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CardMedia from '@mui/material/CardMedia';

function AplikasiDetail() {
    const { aplikasi_id } = useParams();
    const [aplikasi, setAplikasi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [screenshot, setScreenshot] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setLoading(true);
        getData();
    }, [aplikasi_id]);

    const getData = () => {
        axios({
            url: `/get_aplikasi_detail/${aplikasi_id}`,
            method: 'GET',
        }).then((e) => {            
            const { rows, screenshot } = e.data;
            setAplikasi(rows);
            setScreenshot(screenshot);
            setLoading(false);
        });
    }

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    return (
        <BaseLayout
            title=""
            breadcrumb={[
                { label: "Home", route: "/" },
                { label: "Aplikasi", route: "/informasi/aplikasi" },
                { label: loading ? "Menuat..." : aplikasi.nama },
            ]}
        >
            <Grid container spacing={0}>
                <Grid item container sm={12}>
                    <Grid item sm={2}>
                        <MKBox position="relative" borderRadius="xxl" mx={2}>
                            <MKBox
                                component="img"
                                src={aplikasi.logo}
                                alt={"oky"}
                                borderRadius="xxl"
                                width="145px"
                                height="145px"
                                position="relative"
                                zIndex={1}
                            />
                            <MKBox
                                borderRadius="xxl"
                                shadow="md"
                                width="145px"
                                height="145px"
                                position="absolute"
                                left={0}
                                top={0}
                                sx={{
                                    backgroundImage: `url(${aplikasi.logo})`,
                                    transform: "scale(0.94)",
                                    filter: "blur(12px)",
                                    backgroundSize: "cover",
                                }}
                            />
                        </MKBox>
                    </Grid>
                    <Grid item sm={10}>
                        <MKTypography>{ aplikasi.nama }</MKTypography>
                        <MKTypography>{ aplikasi.client }</MKTypography>
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Divider/>
                    <MKTypography>Screenshots</MKTypography>
                    <div style={{ display: 'flex', width: (!fullScreen ? '100%' : '350px'), overflowY: (!fullScreen ? 'auto' : 'scroll'), overflowX: (!fullScreen ? 'auto' : 'scroll') }}>
                    {/* <Grid container spacing={1} style={{ display: 'flex' }}>
                        {
                            screenshot.map((opt, key) => {
                                return (
                                    <Grid key={key} item sm={2} xl={2}>
                                        <Card sx={{ minWidth: 150 }}>
                                            <CardActionArea
                                                // onClick={() => { setOpen(true); setImangesView(opt) }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    height="311"
                                                    image={opt.path}
                                                    alt={""}
                                                    style={{ margin: 0 }}
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid> */}
                    {
                        screenshot.map((opt, key) => {
                            return (
                                <img
                                    style={{ minHeight: '100px', maxHeight: '250px', margin: 5 }}
                                    src={opt.path}
                                />
                            )
                        })
                    }
                    </div>
                    <Divider/>
                </Grid>
                <Grid item sm={12}>
                    <div dangerouslySetInnerHTML={{__html: (aplikasi.deskripsi || '').replace(/style="height: auto;width: auto/g, 'style="height: 100%;width: 100%"') }} />
                    <Divider/>
                </Grid>
            </Grid>
            
        </BaseLayout>
    )
}

export default AplikasiDetail;