import { Card } from '@mui/material';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './style.css';

function Calender() {
  const [value, onChange] = useState(new Date());

  return (
    <Card
      style={{
        padding: '8px',
      }}
    >
      <Calendar onChange={onChange} value={value} />
    </Card>
  );
}

export default Calender;