/* eslint-disable */
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Card, Link } from "@mui/material";

function Support(props) {
    const { client } = props;

    return (
        <MKBox component="section">
            <Grid container item xs={12} lg={6} justifyContent="center" mx="auto">
                <MKTypography variant="h4" mb={3} mt={2}>
                    Didukung oleh
                </MKTypography>
            </Grid>
            <Grid container justifyContent="center" justifyItems={"center"} mx="auto">
                <Grid item xs={3} sm={1}></Grid>
                <Grid item xs={12} sm={10}>
                    <Grid container spacing={1} justifyContent="center" justifyItems={"center"}>
                    {
                        client.map((row, key) => {
                        const imgView = row.logo ? row.logo : ("http://www.simply-chalets.co.uk/img/noimage.png")

                        return (
                            <Link key={key} target="_blank" href={row.link_web}>
                                <Card style={{ margin: 3, padding: 5 }}>
                                    <img src={imgView} height={65} style={{ borderRadius: 5, padding: 2 }}/>
                                </Card>
                            </Link>
                        )
                        })
                    }
                </Grid>
                </Grid>
                <Grid item xs={3} sm={1}></Grid>
            </Grid>
        </MKBox>
    );
}

export default Support;
