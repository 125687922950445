/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import UKSFooter from "examples/Footers/UKSFooter";
import Posts from "pages/UKS/Home/sections/Posts";
import BuiltByDevelopers from "pages/UKS/Home/components/BuiltByDevelopers";
// import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/uks-background.png";
import AppConfig from "AppConfig";
import { useEffect, useState } from "react";
import axios from 'axios';

import Support from "./sections/Support";
import Aplikasi from "./sections/Aplikasi";
import Information from "./sections/Information";
import InformationCard from "./sections/InformasiCard";
// import Stratifikasiuks from "./sections/Stratifikasiuks";
import Infografis from "./sections/Infografis";
import BukuPanduan from "./sections/BukuPanduan";
import Video from "./sections/Video";
import Develop from "./sections/Develop";
import { textAlign } from "@mui/system";
import SekolahSehat from "./sections/SekolahSehat";
import { Skeleton } from "@mui/material";
// import FormulirStratifikasi from "./sections/FormulisStratifikasi";
// import StratifikasiTable from "./sections/StratifikasiTable";

const defaultRekapWilayah = {
  propinsi: [],
  kabupaten: []
}

const empatKementerian = ['Kemenkes','Kemendagri','Kemenag','Kemdikbudristek'];
const support_ = ['WHO','GIZ','UNFPA','Pemerintah Pusat','SNV','WFP','UNICEF'];
const mitra_ = ["nutrifood", "Hisminu", "kao", "danone", "Trakindo", "unicharm", "Bobo", "Papoe", "Badan Pangan Nasional", "Red Nose Foundation", "awina", "LP Ma'arif NU", "Gamma Persada", "PT Asaba", "Nestle", "Haula Toys", "Wardhana", "unilever", "PT Teradata Indonusa", "Advan", "SCI", "savethechildren", "La Moringa", "Yayasan Sagasitas Indonesia", "maleo_group", "Wahana Visi Indonesia", "PT Dwida Jaya Tama", "Inke Maris Associates", "Inovesa", "Acer", "Pamduta", "Aflah", "SKE", "PT Macananjaya Cemerlang", "AIA", "Frisian Flag", "dn", "CV Orion"];

// const defaultSlider = [{
//   'title':'Usaha Kesehatan Sekolah/Madrasah',
//   'konten':'Usaha Kesehatan Sekolah/Madrasah (UKS/M) merupakan upaya satuan pendidikan dalam menanamkan, menumbuhkan, mengembangkan serta meningkatkan kemampuan hidup sehat, dengan penerapan Perilaku Hidup Bersih dan Sehat (PHBS), serta derajat kesehatan peserta didik melalui pelaksanaan Trias UKS',
//   'images':'https://is3.cloudhost.id/storagedirectus1/manajemen_uks/files/images/zDq1wxVbwChhT8MFl78CxjYbW1OR0ZaKZ9n3eTwP.jpg'}]

function Home() {
  const [client, setClient] = useState([]);
  const [support, setSupport] = useState([]);
  const [mitra, setMitra] = useState([]);
  const [berita, setBerita] = useState([]);
  const [praktekBaik, setPraktekBaik] = useState([]);
  const [rekapWilayah, setRekapWilayah] = useState(defaultRekapWilayah);
  const [infografis, setIfografis] = useState([]);
  const [bukupanduan, setBukupanduan] = useState([]);
  const [video, setVideo] = useState([]);
  const [slider, setSlider] = useState([]);
  const [sliderLoading, setSliderLoading] = useState(true);

  useEffect(() => {
    axios({ url : '/api/setVisitor', method: 'GET'});

    axios({ url: `/getClient?name=${empatKementerian}`, method: 'GET' })
    .then((e) => {
      setClient(e.data.rows);
    });

    axios({ url: `/getClient?name=${support_}`, method: 'GET' })
    .then((e) => {
      setSupport(e.data.rows);
    });

    axios({ url: `/getClient?name=${mitra_}&limit=9999`, method: 'GET' })
    .then((e) => {
      setMitra(e.data.rows);
    });

    axios({ url: `/get_berita/0?limit=4`, method: 'GET' })
    .then((e) => {
      setBerita(e.data.rows);
    });

    axios({ url: `/get_praktekbaik/1?limit=4&kategori_artikel_id=-`, method: 'GET' })
    .then((e) => {
      setPraktekBaik(e.data.rows);
    })

    axios({ url: `/getRekapWilayah`, method: 'GET' })
    .then((e) => {
      setRekapWilayah(e.data);
    })

    axios({ url: `/get_publikasi/0?halaman=infografis&limit=${4}`, method: 'GET' })
    .then((e) => {
      setIfografis(e.data.rows);
    })

    axios({ url: `/get_publikasi/0?halaman=buku-panduan&limit=${4}`, method: 'GET' })
    .then((e) => {
      setBukupanduan(e.data.rows);
    })

    axios({ url: `/get_publikasi/0?halaman=video&limit=${4}`, method: 'GET' })
    .then((e) => {
      setVideo(e.data.rows);
    })

    axios({ url: `/getSliders`, method: 'GET' })
    .then((e) => {
      setSliderLoading(false);
      setSlider(e.data);
    })
  }, []);

  const aplikasi = [
    {
      nama: 'Aplikasi OKY',
      link: 'https://play.google.com/store/apps/details?id=com.oky.id',
      logo: 'https://is3.cloudhost.id/storagedirectus1/manajemen_uks/files/images/n4tHzwyBiKpuTS8eRPz5C8j0DHDrA40UZ9d46Ztp.webp',
      images: '',
      deskripsi: 'Oky is a mobile app for girls (age 10+) that helps you take control of your periods and your lives through a bit of fun!',
      client: 'Oky: the period tracker app for girls'
    }
  ];

  return (
    <>
      <DefaultNavbar
        // routes={routes}
        routes={[]}
        action={{
          type: "external",
          route: "",
          label: "free download",
          color: "success",
        }}
        sticky
      />
      <BuiltByDevelopers slider={slider} />
      {/* <MKBox
        width="100%"
        // style={{
        //   backgroundImage: 'url(/pattern.png)',
        // }}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }}) =>
          `${linearGradient(
            rgba('4caf50', 0),
            rgba('2F9933', 0)
          )}, url(/header_uks_new.png)`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          minHeight: 400
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
            <MKTypography
              variant="h2"
              color="white"
              mb={1}
              style={{ color: '#003402', textShadow: '#fff 1px 0 10px, #fff 1px 0 10px, #fff 1px 0 10px, #fff 1px 0 10px', marginBottom: 0 }}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: '36,8x',
                  marginTop: '12px',
                  textAlign: 'center',
                },
              })}
            >
              { AppConfig.sub_judul }
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              mt={1}
              style={{ fontSize: 16,color: '#003402', textShadow: 'rgb(255, 255, 255) 1px 0px 10px, rgb(255, 255, 255) 1px 0px 10px', marginTop: 0 }}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: '16px',
                },
              })}
            >
             "<i>{ AppConfig.client }</i>"
            </MKTypography>
            <MKTypography
              variant="caption"
              color="white"
              textAlign="center"
              mt={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: '10px',
                },
              })}
            >
            <a href={'https://simpuh.kemenag.go.id/regulasi/pb4menteri_2014.pdf'} target="_blank" style={{ color: '#003402', textShadow: 'rgb(255, 255, 255) 1px 0px 10px, rgb(255, 255, 255) 1px 0px 10px' }}>
              <i>{AppConfig.sub_sub_judul }</i>
            </a>
            </MKTypography>
          </Grid>
          <Grid container item xs={12} lg={4} justifyContent="center" mx="auto">
            <div style={{ marginTop: 5 }}>
              {
                client.map((img, keyImg) =><a key={keyImg} href={img.link_web} target={"_blank"}><img alt={img.nama_lengkap} src={img.logo} width="65"/></a>)
              }
            </div>
          </Grid>
        </Container>
      </MKBox> */}
      <Card
        sx={{
          p: { xs: 0.5, lg: 2 },
          mx: { xs: 0.5, lg: 3 },
          mt: -11,
          mb: 4, 
        }}
        style={{
          background: 'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 1) 80px)',
          // backgroundImage: 'url(/pattern.png)',
          // backgroundSize: 'contain'
        }}
      >
        {/* <Container sx={{ my:4 }}>
          { sliderLoading? (
            <Skeleton variant="rounded" sx={{ width: "100%", height: {xs: "160px", lg: "300px"}, borderRadius: "15px" }} />
          ) : (
            <BuiltByDevelopers slider={slider} />
          )}
        </Container> */}

        <Container sx={{ mb: 2 }}>
          <SekolahSehat mitra={mitra} />
        </Container>

        <Container sx={{ mb: 6 }}>
          <InformationCard />
        </Container>

        <Container sx={{ mb: 6 }}>
          <Posts berita={berita} praktekbaik={praktekBaik}/>
        </Container>

        <Container sx={{ mb: 6 }}>
          <Infografis infografis={infografis} />
        </Container>

        <Container sx={{ mb: 6 }}>
          <BukuPanduan bukupanduan={bukupanduan} />
        </Container>

        <Container sx={{ mb: 6 }}>
          <Video video={video} />
        </Container>

        <Container sx={{ mb: 6 }}>
          <Information rekapWilayah={rekapWilayah}/>
        </Container>

        <Container sx={{ mb: 6 }}>
          <Aplikasi aplikasi={aplikasi} />
        </Container>

        {/* <Container sx={{ mb: 6 }}>
          <Stratifikasiuks />
        </Container> */}
        
        {/* <Container sx={{ mb: 6 }}>
          <FormulirStratifikasi/>
        </Container>

        <Container sx={{ mb: 6 }}>
          <StratifikasiTable/>
        </Container> */}
        
        <Container sx={{ mb: 6 }}>
          <Develop client={client} />
        </Container>
        
        {/* <Container sx={{ mb: 6 }}>
          <Support client={support} />
        </Container> */}

      </Card>
      <MKBox pt={6} px={1} mb={6}>
        <UKSFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
