/* eslint-disable */
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

function HorizontalTeamCard2({ image, name, date, link }) {

    // const handleDate = (value) => {
    //     const time = new Date(value);
    //     const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    //     return `${time.getDate()} ${bulan[parseInt(time.getMonth())-1]} ${time.getFullYear()}`;
    // }

    const handleDate = (value) => {
        if(value){
            const time = new Date(value);
            const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

            return `${time.getDate()} ${bulan[parseInt(time.getMonth())]} ${time.getFullYear()}`;
        }
    }

    return (
        <Card sx={{ mb: 1 }} style={{ minHeight: 100 }}>
            <Grid container>
                <Grid item xs={3.5} lg={5}>
                    <MKBox width="100%" px={1} mt={1}>
                        <MKBox
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: 'cover'
                            }}
                            alt={name}
                            width={'100%'}
                            height={85}
                            borderRadius="md"
                            shadow="lg"
                        />
                    </MKBox>
                </Grid>
                <Grid item xs={8.5} lg={7} sx={{ my: "auto" }}>
                    <MKBox pt={{ xs: 1, lg: 1.5 }} pb={1.5} pr={1} pl={{ xs: 1, sm: 0, lg: 0 }} lineHeight={1}>
                        <MKTypography variant="caption" color="text">
                            <CalendarTodayIcon/>&nbsp;{handleDate(date)}
                        </MKTypography>
                        <Link href={link}>
                            <MKTypography variant="h6" style={{ fontSize: 12 }} color="warning" >{ name.substr(0, 40) }</MKTypography>
                        </Link>
                    </MKBox>
                </Grid>
            </Grid>
        </Card>
    );
}

// Typechecking props for the HorizontalTeamCard2
HorizontalTeamCard2.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
};

export default HorizontalTeamCard2;
