/* eslint-disable */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function Information({ konten }) {
  return (
    <MKBox component="section" py={1} my={1}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>          
          {
            konten.map((opt, key) => {

              return (
                <Grid item xs={12} md={4} key={key}>
                  <FilledInfoCard
                    variant="contained"
                    color="success"
                    icon="class"
                    title={opt.title}
                    description={opt.description + '...'}
                    action={{
                        type: "internal",
                        route: opt.url,
                        label: "Selengkapnya",
                    }}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
