/* eslint-disable */
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import MKButton from "components/MKButton";

function TableInfoCard({ variant, color,  title, description, action, table }) {
  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };

  return (
    <MKBox
      // display={{ xs: "block", md: "flex" }}
      variant={variant}
      bgColor={variant === "contained" ? "grey-100" : color}
      borderRadius="xl"
      pt={3.5}
      pb={3}
      px={1}
    >
      <MKBox pt={{ xs: 3, md: 0 }} pl={{ xs: 0, md: 1 }} lineHeight={1}>
        <MKTypography
          display="block"
          variant="h6"
          color={variant === "contained" || color === "light" ? "dark" : "white"}
          fontWeight="bold"
          align="center"
          mb={1}
          style={{ fontSize: 13 }}
        >
          {title}
        </MKTypography>
        <MKTypography
          display="block"
          variant="caption"
          color={variant === "contained" || color === "light" ? "text" : "white"}
          align="center"
          mb={2}
        >
          {description}
        </MKTypography>

        <TableContainer component={Paper}>
            <Table aria-label="dense" size="small">
                <TableBody>
                    <TableRow>
                        <TableCell style={{ fontSize: 12 }} align="center">
                          <MKTypography fontWeight="bold" variant="caption">No</MKTypography>
                        </TableCell>
                        <TableCell style={{ fontSize: 12 }} align="left">
                          <MKTypography fontWeight="bold" variant="caption">Wilayah</MKTypography>
                        </TableCell>
                        <TableCell style={{ fontSize: 12 }} align="center">
                          <MKTypography fontWeight="bold" variant="caption">(%)</MKTypography>
                        </TableCell>
                    </TableRow>

                    {
                        table.map((opt, key) => (
                            <TableRow key={key}>
                                <TableCell style={{ padding: '10px 0px 10px 0px', fontSize: 11 }} align="center">
                                  <MKTypography variant="caption">{ key+1 }</MKTypography>
                                </TableCell>
                                <TableCell style={{ padding: '10px 0px 10px 0px', fontSize: 11 }} align="left">
                                  <MKTypography variant="caption"> { opt.nama_wilayah } </MKTypography>
                                </TableCell>
                                <TableCell style={{ padding: '10px 0px 10px 0px', fontSize: 11 }} align="center">
                                  <MKTypography variant="caption">{ parseFloat(opt.persentase) }</MKTypography>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                    
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{ marginTop: 10 }}>
        {action && action.type === "external" ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            color="success"            
            fontWeight="regular"
            size="small"
            fullWidth={true}
            sx={buttonStyles}
          >
            {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKButton>
        ) : null}
        {action && action.type === "internal" ? (
          <MKButton
            component={Link}
            to={action.route}
            color="success"            
            fontWeight="regular"
            size="small"
            fullWidth={true}
            sx={buttonStyles}
          >
            {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKButton>
        ) : null}
        </div>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the TableInfoCard
TableInfoCard.defaultProps = {
  variant: "contained",
  color: "info",
  action: false,
};

// Typechecking props for the TableInfoCard
TableInfoCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
//   icon: PropTypes.node.isRequired,
  // title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default TableInfoCard;
