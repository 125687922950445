/* eslint-disable */
import { Grid } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function AgendaDetail() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { agenda_id } = useParams();

    useEffect(() => {
        getData();        
    }, []);

    const getData = () => {
        axios({
            url: `/get_agenda_detail/${agenda_id}`,
            method: "GET"
        }).then((e) => {
            setData(e.data);
            setLoading(false);
        })
    }

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    return (
        <BaseLayout 
            title={''}
            breadcrumb={[
                { label: "Agenda", route: "/informasi/agenda" },
                { label: loading ? 'Memuat...' : data.nama || '' }
            ]}
        >
            <Grid container spacing={2} mb={12}>
                <Grid item xs={12} sm={9}>
                    <MKBox
                        width="100%"
                        position="relative"
                        borderRadius="xl"
                        shadow="lg"
                        sx={{ overflow: "hidden" }}
                    >
                        <MKBox display={"block"}>
                            <MKBox width="100%" p={3}>
                                <MKBox
                                    width="100%"
                                    borderRadius="xl"
                                    sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}
                                >                            
                                    <MKTypography variant="h5" sx={{ mb: 3 }}>{ data.nama || '' }</MKTypography>
                                    <div dangerouslySetInnerHTML={{__html: (data.konten || '').replace(/style="height: auto;width: auto/g, 'style="height: 100%;width: 100%"') }} />
                                    <MKTypography variant="body2" sx={{ mt: 5 }}>{ data.jenis_agenda || '' }</MKTypography>
                                    <MKTypography variant="caption" sx={{ mb: 3 }}>{ handleTanggal(data.mulai) || '' } s/d { handleTanggal(data.selesai) || '' }</MKTypography>
                                </MKBox>
                            </MKBox>
                        </MKBox>
                    </MKBox>
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default AgendaDetail;