/* eslint-disable */
import { Grid, Icon, Card, TextField, ButtonGroup, Stack, Pagination, Skeleton, Table, TableBody, TableRow, TableCell, CardMedia, CardActionArea, CardContent, Typography, Divider, Dialog, DialogContent, Menu, MenuItem } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import MKButton from "components/MKButton";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useNavigate, useParams } from "react-router-dom";

import { IconButton } from "@mui/material";
import AppsIcon from '@mui/icons-material/Apps';
import CloseIcon from '@mui/icons-material/Close';

// ICON
import EventIcon from '@mui/icons-material/Event';

function Infografis() {
    const navigate = useNavigate();
    const { page,publikasiId } = useParams();
    const [countPage, setCountPage] = useState(1);
    const [publikasi, setPublikasi] = useState([]);
    const [publikasiDetail, setPublikasiDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [select, setSelect] = useState([]);
    const [open, setOpen] = useState(false);
    const [embed, setEmbed] = useState([]);

    const [selected, setSelected] = useState('');
    const [selectedLabel, setSelectedLabel] = useState('');
    const [dropdown, setDropdown] = useState(null);
    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);

    const limitPage = 12;
    const thisPage = parseInt(page) || 1;

    useEffect(() => {
        if(!publikasiId){
            getData(thisPage);
        }
    }, [selected, page]);
    useEffect(() => {
        if(publikasiId){    
            axios({ 
                url: `/get_publikasi_perdata?publikasi_id=${publikasiId}`,
                method: 'GET',
            }).then((e) => {
                const { data } = e;
                setPublikasiDetail(data);
                setLoading(false);
            })
        }
    }, [publikasiId]);


    const getData = (thisPage) => {
        setLoading(true);
        axios({
            url: `/get_publikasi/${thisPage}?halaman=infografis&search=${search}&jenis_dokumen_id=${selected}&limit=${limitPage}`,
            method: 'GET',
        }).then((e) => {
            const { rows, count, select } = e.data;
            const data = Math.ceil(count/limitPage);
            setCountPage(data);
            setPublikasi(rows);
            setSelect(select.jenis_dokumen);
            setLoading(false);
        })
    }

    const handleTanggal = (value) => {
        var mydate = new Date(value);
        var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
        var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();

        return str;
    }

    const handleSearch = () => {
        getData(0);
    }

    const handleOpen = (opt) => {
        const slideImages = opt.files.map((opt) => { return {url: opt.path.includes('api-uks.kemdikbud.go.id') ?  opt.path : `https://is3.cloudhost.id/storagedirectus1/${opt.path}`, caption: opt.nama}});
        setOpen(true);
        setEmbed(slideImages);
    }

    const handleOpenPage = (opt) => {
        navigate(`/dokumen/publikasi/infografis/detail/${opt.publikasi_id}`);
        // const slideImages = opt.files.map((opt) => { return {url: opt.path.includes('api-uks.kemdikbud.go.id') ?  opt.path : `https://is3.cloudhost.id/storagedirectus1/${opt.path}`, caption: opt.nama}});
        // setOpen(true);
        // setEmbed(slideImages);
    }

    const handleChange = (event, value) => {
        if(value === 1){
            navigate(`/dokumen/publikasi/infografis`);
        }else{
            navigate(`/dokumen/publikasi/infografis/${value}`);
        }
    }

    const iconStyles = {
        ml: 1,
        fontWeight: "bold",
        transition: "transform 200ms ease-in-out",
    };

    const dropdownIconStyles = {
        transform: dropdown ? "rotate(180deg)" : "rotate(0)",
        ...iconStyles,
    }

    if(publikasiId){

        // var substr = str.substring(str.length-4, str.length);
        const titlePublikasi = publikasiDetail?.length > 0 ? publikasiDetail[0].nama.substring(0,publikasiDetail[0].nama.length-5) : '';
        return <BaseLayout
            title={`Infografis ${titlePublikasi}`}
            breadcrumb={[
                { label: "Publikasi" },
                { label: "Infografis" },
                { label: "Detail" },
            ]}
            >
            <Grid container spacing={2} style={{ marginBottom: '30px' }}>
            { !loading && publikasiDetail?.length === 0 && (<Grid item xs={12} md={12}><center style={{ margin: '3rem' }}>...Tidak ada data untuk di tampil...</center></Grid>) }
                { publikasiDetail?.map((opt, index) => (
                <Grid item xs={12} md={12}>
                    <Card sx={{ boxShadow: 3 }}>
                        <img src={opt.path} style={{ width: '100%' }} />
                    </Card>
                    </Grid>
                )) }
            </Grid>
                
            </BaseLayout>
    }
    return (
        <BaseLayout
            title="Infografis"
            breadcrumb={[
                { label: "Publikasi" },
                { label: "Infografis" },
            ]}
        >
            <Grid container spacing={2} style={{ marginBottom: '30px' }}>

                <Grid container spacing={1} item md={12}>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid container spacing={3} item xs={0} md={10}>
                        <Grid item xs={12} md={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    name="pencarian"
                                    // size="small"
                                    fullWidth
                                    placeholder="Pencarian..."
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    onKeyPress={e => {
                                        if(e.key === 'Enter'){
                                            handleSearch()
                                        }
                                    }}
                                />
                                &nbsp;
                                <MKButton color="success" onClick={handleSearch} style={{ fontSize: 11 }}>Cari</MKButton>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} md={5}></Grid> */}
                        {/* <Grid item xs={12} md={3}>
                            <MKButton variant="gradient" color="warning" fullWidth={true} size="small" onClick={openDropdown} style={{ fontSize: 11 }}>
                                { selectedLabel || 'Semua'} <Icon sx={dropdownIconStyles}>expand_more</Icon>
                            </MKButton>
                            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
                                <MenuItem selected={selected === ''} style={{ fontSize: 11 }} onClick={e => { setSelected(''); closeDropdown()}}>Semua</MenuItem>
                                {
                                    select.map((opt, key) =>
                                        <MenuItem
                                            key={key}
                                            selected={selected === opt.value}
                                            value={opt.value}
                                            style={{ fontSize: 11 }}
                                            onClick={e => {
                                                setSelected(opt.value);
                                                setSelectedLabel(opt.label);
                                                closeDropdown();
                                            }}
                                        >
                                            { opt.label.toUpperCase() }
                                        </MenuItem>   
                                    )
                                }
                            </Menu>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                </Grid>

                <Grid container spacing={2} item md={12}>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid container spacing={3} item xs={0} md={10}>
                        { !loading && publikasi.length === 0 && (<Grid item xs={12} md={12}><center style={{ margin: '3rem' }}>...Tidak ada data untuk di tampil...</center></Grid>) }
                        { loading && [1,2,3].map((opt, key) => <Grid key={key} item xs={6} md={4}><Skeleton variant="rectangular" width={"100%"} height={510} /></Grid>) }
                        { !loading && publikasi.map((opt, key) => {

                            
                            const slideImages = opt.files.map((opt) => { return {url: opt.path.includes('api-uks.kemdikbud.go.id') ?  opt.path : `https://is3.cloudhost.id/storagedirectus1/${opt.path}`, caption: opt.nama}});

                            return (
                                <Grid key={key} item xs={12} md={4}>
                                    <Card>
                                        {/* <CardActionArea onClick={() => handleOpen(opt)}> */}
                                            {slideImages.length === 1 && (
                                                <CardMedia
                                                    component="img"
                                                    height="400"
                                                    width="100%"
                                                    image={slideImages[0].url}
                                                    style={{
                                                        margin: 0,
                                                        width: '100%'
                                                    }}
                                                    alt={opt.nama}
                                                />
                                            )}
                                            {slideImages.length > 1 && (
                                            <div className="slide-container">
                                                    <Slide>
                                                        {slideImages.map((slideImage, index)=> (
                                                            <div className="each-slide" key={index}>
                                                                <div style={{'backgroundImage': `url(${slideImage.url})`, height: 400, backgroundSize: 'cover', borderRadius: '0.75rem'}}>
                                                                    {/* <span style={{ backgroundColor: '#fff', padding: 2, opacity: '0.5', fontSize: 12 }}>{slideImage.caption}</span> */}
                                                                </div>
                                                            </div>
                                                        ))} 
                                                    </Slide>
                                                </div>
                                            )}
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 15, minHeight: 30 }}>
                                                    { opt.nama }
                                                </Typography>
                                                <MKButton variant="contained" size="small" color="success" onClick={() => handleOpen(opt)}>Lihat</MKButton>
                                                &nbsp;
                                                &nbsp;
                                                <MKButton variant="contained" size="small" color="success" onClick={() => handleOpenPage(opt)}>Lihat Penuh</MKButton>
                                            <Divider/>
                                            <Typography variant="caption" color="text.secondary">
                                                <div style={{ display: 'flex', alignItems: 'center' }}> <EventIcon/>&nbsp;{handleTanggal(opt.create_date)}</div>
                                            </Typography>
                                            </CardContent>
                                        {/* </CardActionArea> */}
                                    </Card>
                                </Grid>
                            )
                        }) }
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={12} container spacing={1} justifyContent="center" mx="auto">
                        <Stack spacing={2}>
                            <Pagination
                                count={countPage}
                                defaultPage={thisPage}
                                color="secondary"
                                showFirstButton
                                showLastButton
                                onChange={handleChange}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                maxWidth="xl"
                fullWidth={true}
                onClose={() => setOpen(false)}
            >            
                <Grid spacing={1}>
                    <Grid item sm={12} sx={{ display:'flex' }} justifyContent="flex-end">
                        <IconButton sx={{ padding: '10px 15px' }} size="small" color="error" onClick={() => setOpen(false)}><CloseIcon fontSize="medium"/> Tutup</IconButton>
                    </Grid>
                    <Grid item sm={12}>
                        {embed.map((opt, key) => <img key={key} src={opt.url} width="100%" style={{ marginBottom: 12 }} />)}
                    </Grid>
                </Grid>
            </Dialog>

        </BaseLayout>
    )
}

export default Infografis;
